import { useState, useEffect } from 'react';
import { Typography, Stack, Card, CircularProgress } from '@mui/material';

import useStripe from '@hooks/useStripe';
import useOrg from '@hooks/useOrg';
import { PricingPageProduct } from '@shared/types/stripe';

import SubscriptionStatus from './SubscriptionStatus';
import PlanPicker from './PlanPicker';

// ----------------------------------------------------------------------
export default function Billing() {
  const { getProducts, subscription, customerId } = useStripe();
  const { org } = useOrg();

  // --------------- state ---------------
  const [isLoading, setLoading] = useState(true);
  const [products, setProducts] = useState<PricingPageProduct[]>();
  const [currentProduct, setCurrentProduct] = useState<PricingPageProduct>();

  // --------------- effects ---------------
  useEffect(() => {
    const getProductsData = async () => {
      setLoading(true);
      if (!org) {
        return;
      }

      const data = await getProducts({ currency: org.currency, orgId: org.id });

      if (subscription) {
        // INFO: After deploying Unified Pricing this won't find the current product if it was archieved on Stripe
        // This is not an issue since we hide the plan picker for customers that have an active subscription
        const plan = data.find((p) => p.id === subscription.product.id);
        if (plan) {
          setCurrentProduct(plan);
        }
      }

      setProducts(data);
      setLoading(false);
    };

    getProductsData();
  }, [getProducts, subscription, org]);

  if (isLoading || !products) {
    return (
      <Card sx={{ p: 3 }}>
        <Stack spacing={3} direction="column" alignItems="center" my={8}>
          <CircularProgress />
          <Typography variant="subtitle2">Loading...</Typography>
        </Stack>
      </Card>
    );
  }

  return (
    <Stack spacing={2}>
      <SubscriptionStatus />
      {((customerId && !subscription) || !customerId) && (
        <PlanPicker
          products={products}
          currentProduct={subscription?.cancelAtPeriodEnd ? undefined : currentProduct}
        />
      )}
    </Stack>
  );
}
