import { useState, useEffect, useMemo } from 'react';
import { Typography, Stack, Button, Card, Slider, Divider } from '@mui/material';
import { isArray } from 'lodash';
import numeral from 'numeral';

import { Stripe } from '@shared/types';
import useOrg from '@hooks/useOrg';
import useStripe from '@hooks/useStripe';
import useAuth from '@hooks/useAuth';
import ContactUsDialog from './ContactUs';

// ----------------------------------------------------------------------
type Props = {
  readonly products: Stripe.PricingPageProduct[];
  readonly currentProduct?: Stripe.PricingPageProduct;
};

// ----------------------------------------------------------------------
export default function PlanPicker({ products, currentProduct }: Props) {
  const { user } = useAuth();
  const { org } = useOrg();
  const { createCheckoutSession, createPortalSession, customerId, subscription } = useStripe();

  // --------------- state ---------------
  const [disablePurchase, setDisablePurchase] = useState(false);
  const [dialogOpen, dialogSetOpen] = useState(false);
  const [plan, setPlan] = useState(currentProduct);

  const sliderMarks = [
    { value: 0, label: '25' },
    ...products.map((plan, i) => ({
      value: i + 1,
      label: numeral(plan.donor_limit).format('00,00'),
    })),
    { value: products.length + 1, label: '20,000+' },
  ];
  const [sliderSpecial, setSliderSpecial] = useState<'free' | 'contact'>();
  const sliderDefault = useMemo(() => {
    const productIndex = products.findIndex((p) => p.id === currentProduct?.id);
    return productIndex === -1 ? 0 : productIndex + 1;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (plan) {
      const newPlan = products.find((p) => p.donor_limit === plan.donor_limit);
      setPlan(newPlan);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPlan, products]);

  const ctaLabel = useMemo(() => {
    if (sliderSpecial === 'free' && plan?.id !== currentProduct?.id) {
      return 'Cancel Subscription';
    } else if (sliderSpecial === 'contact') {
      return 'Contact Us';
    } else if (
      plan?.id === currentProduct?.id &&
      plan?.recurring_interval === currentProduct?.recurring_interval
    ) {
      return 'Current Plan';
    }
    return 'Select Plan';
  }, [currentProduct, plan, sliderSpecial]);

  // --------------- actions ---------------
  const onPlanChange = (_: any, value: number | number[]) => {
    const planIndex = isArray(value) ? value[0] : value;
    if (planIndex === 0) {
      setSliderSpecial('free');
      setPlan(undefined);
    } else {
      setSliderSpecial(planIndex === sliderMarks.length - 1 ? 'contact' : undefined);
      setPlan(products[planIndex - 1]);
    }
  };

  const selectPlan = async () => {
    setDisablePurchase(true);
    if (!org || !user) {
      console.error('Missing org, user or plan');
      setDisablePurchase(false);
      return null;
    }

    let sessionUrl: string;
    if (subscription?.product && customerId) {
      sessionUrl = await createPortalSession({
        customerId,
        orgId: org.id,
      });
    } else {
      if (!plan) {
        console.error('Missing plan');
        setDisablePurchase(false);
        return;
      }
      sessionUrl = await createCheckoutSession({
        lookupKey: plan.lookup_key,
        orgId: org.id,
        customerId,
        email: user.email,
      });
    }
    setDisablePurchase(false);
    window.location.replace(sessionUrl);
  };

  const handleContactUs = async () => {
    dialogSetOpen(true);
  };

  return (
    <Card sx={{ p: 3, color: (theme) => theme.palette.grey[600], maxWidth: 600 }}>
      <Stack spacing={2}>
        <Stack direction="row" justifyContent="space-between">
          <Stack>
            <Typography variant="h5">Change plans</Typography>
            <Typography variant="body2">Only pay for what you need</Typography>
          </Stack>
        </Stack>

        <Stack>
          <Typography variant="subtitle1">Donors</Typography>
          <Stack px={1}>
            <Slider
              size="medium"
              defaultValue={sliderDefault}
              min={0}
              max={sliderMarks.length - 1}
              step={null}
              marks={sliderMarks}
              valueLabelDisplay="off"
              onChange={onPlanChange}
            />
          </Stack>
        </Stack>

        <Divider />

        <Stack direction="row" justifyContent="space-between">
          {sliderSpecial !== 'contact' ? (
            <Stack direction="row" alignItems="baseline" spacing={0.5}>
              <Typography variant="h4" color="text.primary">
                {numeral(plan ? plan.details[0].unit_amount / 100 : 0).format('$0,0')}
              </Typography>
              <Typography variant="body1">/ year</Typography>
            </Stack>
          ) : (
            <Stack />
          )}

          <Button
            variant="contained"
            disabled={
              (plan?.id === currentProduct?.id &&
                plan?.recurring_interval === currentProduct?.recurring_interval &&
                sliderSpecial !== 'contact') ||
              disablePurchase
            }
            onClick={sliderSpecial === 'contact' ? handleContactUs : selectPlan}
          >
            {ctaLabel}
          </Button>
        </Stack>
      </Stack>
      <ContactUsDialog open={dialogOpen} setOpen={dialogSetOpen} />
    </Card>
  );
}
