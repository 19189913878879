import { useCallback } from 'react';
import numeral from 'numeral';
import {
  endOfDay,
  endOfMonth,
  endOfYear,
  format,
  formatDistanceToNow,
  isAfter,
  isBefore,
  isEqual,
  isValid,
  startOfDay,
  startOfMonth,
  startOfYear,
  sub,
} from 'date-fns';
import { intersection, compact } from 'lodash';

import useOrg from '@hooks/useOrg';
import { Address, Donor, Receipt, ReceiptAction } from '@shared/types';
import { CountryList, FormatDateList } from '@typedefs/org';
import { parseDateTimezone } from '@redux/slices/donation';
import getMemberEnvNumber from '@utils/getMemberEnvNumber';
import { formatCurrency, formatReceiptNumber } from '@utils/numeralFormat';

// ----------------------------------------------------------------------
const useFormat = () => {
  const { org } = useOrg();
  // ------------------------------
  // DATES
  const isDateValid = useCallback((date: Date | string | number) => isValid(date), []);
  const isDateBetween = useCallback(
    (date: Date | number, from: Date | number, to: Date | number, inclusivity = '[]') => {
      if (!['()', '[]', '(]', '[)'].includes(inclusivity)) {
        throw new Error('Inclusivity parameter must be one of (), [], (], [)');
      }

      const isBeforeEqual = inclusivity[0] === '[',
        isAfterEqual = inclusivity[1] === ']';

      return (
        (isBeforeEqual ? isEqual(from, date) || isBefore(from, date) : isBefore(from, date)) &&
        (isAfterEqual ? isEqual(to, date) || isAfter(to, date) : isAfter(to, date))
      );
    },
    []
  );

  const getDateFormat = useCallback(
    () => org?.dateFormat || FormatDateList[0].value,
    [org?.dateFormat]
  );

  // Convert to the org's date format, being sure to adjust for the timezone so an ISO
  // date in the format YYYY-MM-DD can't be adjusted back to the prior date.
  const fDate = useCallback(
    (date: Date | string | number) =>
      format(parseDateTimezone(date), org?.dateFormat || FormatDateList[0].value),
    [org?.dateFormat]
  );

  // Convert to a long date, being sure to adjust for the timezone so an ISO
  // date in the format YYYY-MM-DD can't be adjusted back to the prior date.
  const fLongDate = useCallback(
    (date: Date | string | number) => format(parseDateTimezone(date), 'MMMM d, yyyy'),
    []
  );

  const fToNow = useCallback(
    (date: Date | string | number) =>
      formatDistanceToNow(new Date(date), {
        addSuffix: true,
      }),
    []
  );

  const fDateToDayStart = useCallback((date?: Date | number) => startOfDay(date || new Date()), []);
  const fDateToDayEnd = useCallback((date?: Date | number) => endOfDay(date || new Date()), []);
  const fDateToMonthStart = useCallback(
    (date?: Date | number) => startOfMonth(date || new Date()),
    []
  );
  const fDateToMonthEnd = useCallback((date?: Date | number) => endOfMonth(date || new Date()), []);
  const fDateToYearStart = useCallback(
    (date?: Date | number) => startOfYear(date || new Date()),
    []
  );
  const fDateToYearEnd = useCallback((date?: Date | number) => endOfYear(date || new Date()), []);
  const fDateToPreviousYearStart = useCallback(
    (date?: Date | number) => sub(startOfYear(date || new Date()), { years: 1 }),
    []
  );
  const fDateToPreviousYearEnd = useCallback(
    (date?: Date | number) => sub(endOfYear(date || new Date()), { years: 1 }),
    []
  );
  const fDateToPreviousYear = useCallback(
    (date?: Date | number) => sub(date || new Date(), { years: 1 }),
    []
  );

  const fDateToISO = useCallback(
    (date?: Date | number) => format(date || new Date(), 'yyyy-MM-dd'),
    []
  );

  const fMonthYear = useCallback(
    (date: Date | number) => format(date || new Date(), 'MMMM yyyy'),
    []
  );

  // New function to format date as  exemple "Sep 26, 2024"
  const fShortDate = useCallback(
    (date: Date | string | number) => format(parseDateTimezone(date), 'MMM d, yyyy'),
    []
  );

  // ------------------------------
  // NUMBERS
  const fCurrency = useCallback(
    (number: string | number, short?: boolean) => formatCurrency(number, short)
  , []);

  // Return a currency value as the underlying number
  const fUnCurrency = useCallback(
    (currency: string) => Number(currency.replace(/[^0-9.-]+/g, '')),
    []
  );

  const fPercent = useCallback((number: number) => numeral(number / 100).format('0.0%'), []);
  const fNumber = useCallback((number: string | number) => numeral(number).format(), []);
  const fShortenNumber = useCallback(
    (number: string | number) => numeral(number).format('0,0a'),
    []
  );
  const fData = useCallback((number: string | number) => numeral(number).format('0.0 b'), []);

  // ------------------------------
  // OTHER

  // Join the elements of an array (assumed to all be non-empty) into an English-language
  // list separated by commas, but with the conjunction between the last two.
  const fJoinWithConjunction = useCallback((arr: string[], conjunction: 'and' | 'or') => {
    if (!arr || !arr.length) return '';
    switch (arr.length) {
      case 1:
        return arr[0];
      case 2:
        return `${arr[0]} ${conjunction} ${arr[1]}`;
      default:
        return `${arr.slice(0, -1).join(', ')} ${conjunction} ${arr[arr.length - 1]}`;
    }
  }, []);

  // Return an address as an array of 4 strings for the up to 4 lines.
  // Include true for isOrg only if the address is an organizations record's address,
  //   rather than a donor's address.
  // Only include the address' country if it's not the same as the organization's country.
  const fAddressLines = useCallback(
    (address?: Address, isOrg?: boolean) => {
      if (!address) return [] as string[];

      let { state } = address;
      if (isOrg) {
        state = state?.toUpperCase() || '';
      }

      const line3 = ((address.city + ' ' + state).trim() + '  ' + address.postalCode).trim();
      let country = '';
      if (org?.address.country !== address.country) {
        country = address.country
          ? `${CountryList.find((c) => c.value === address.country)?.label}`
          : '';
      }
      return compact([address.address1, address.address2, line3, country]);
    },
    [org?.address.country]
  );

  // Return an address as one string, with commas between what would be the up to 4 lines on a
  // mailing label.
  const fAddress = useCallback(
    (address?: Address, isOrg?: boolean) => fAddressLines(address, isOrg).join(', '),
    [fAddressLines]
  );

  /*
   This function returns either the business name if it exists, or the first name followed by the last name. In addition,
    the membernumber can be added, based on the other arguments, as follows:
  1. If none of these arguments are provided dateFrom, dateTo, or memberYear), it returns the empty string.
  2. If dateFrom and dateTo are not provided, it will return the member number for the memberYear.
  3. If a date range is provided (with dateFrom and dateTo), it will return the member number within that date range.
  */
  const fFullName = useCallback(
    (donor?: Donor.Donor, memberYear?: number, dateFrom?: Date, dateTo?: Date) => {
      if (!donor) return '';

      const memberNumber = memberYear
        ? getMemberEnvNumber(donor, memberYear, dateFrom, dateTo)
        : undefined;
      const memberString = org?.memberNumbers && memberNumber ? `#${memberNumber}` : '';

      if (donor.type === 'business') return `${donor.organization} ${memberString}`.trim() || '';
      return `${donor.firstName} ${donor.lastName} ${memberString}`.trim();
    },
    [org?.memberNumbers]
  );

  const fFullLegalName = useCallback((donor?: Donor.Donor) => {
    if (!donor) return '';
    if (donor.type === 'business') return donor.organization || '';
    
    const { firstName, middleName, lastName } = donor;
    return [firstName, middleName, lastName].filter(Boolean).join(' ');
  }, []);

  /*
   This function returns either the business name if it exists, or the last name, first name. In addition,
    the membernumber can be added, based on the other arguments, as follows:
  1. If none of these arguments are provided (dateFrom, dateTo, or memberYear), it returns the empty string.
  2. If dateFrom and dateTo are not provided, it will return the member number for the memberYear.
  3. If a date range is provided (with dateFrom and dateTo), it will return the member number within that date range.
  */
  const fReversedName = useCallback(
    (donor?: Donor.Donor, memberYear?: number, dateFrom?: Date, dateTo?: Date) => {
      if (!donor) return '';

      const memberNumber = memberYear
        ? getMemberEnvNumber(donor, memberYear, dateFrom, dateTo)
        : undefined;
      const memberString = org?.memberNumbers && memberNumber ? `#${memberNumber}` : '';

      const name =
        donor.type === 'business'
          ? donor.organization || ''
          : `${donor.lastName}, ${donor.firstName}`;
      return `${name} ${memberString}`.trim();
    },
    [org?.memberNumbers]
  );

  const fReceiptNumber = useCallback(
    (receiptNumber: number, year: number) => formatReceiptNumber(receiptNumber, year),
    []
  );

  const fReceiptActionType = useCallback(
    (actionType?: ReceiptAction.ReceiptActionType) => {
      const country = org?.address.country;

      switch (actionType) {
        case ReceiptAction.ReceiptActionType.print:
          return 'Print';
        case ReceiptAction.ReceiptActionType.email:
          return 'Email';
        case ReceiptAction.ReceiptActionType.revalidated:
          return 'Original';
        case ReceiptAction.ReceiptActionType.replaced:
          return country === 'ca' ? 'Replaced' : 'Duplicated';
        case ReceiptAction.ReceiptActionType.corrected:
          return 'Corrected';
        case ReceiptAction.ReceiptActionType.replacement:
          return country === 'ca' ? 'Replacement' : 'Duplicate';
        case ReceiptAction.ReceiptActionType.correction:
          return 'Correction';
        case ReceiptAction.ReceiptActionType.donationsAdded:
          return 'Donations added';
        case ReceiptAction.ReceiptActionType.donationCorrected:
          return 'Donation corrected';
        case ReceiptAction.ReceiptActionType.donationDeleted:
          return 'Donation deleted';
        default:
          return 'Original';
      }
    },
    [org?.address.country]
  );

  const fReceiptState = useCallback(
    (receipt?: Receipt.Receipt) => {
      if (!receipt) return '';
      if (receipt.invalidated) return 'Needs Correction';

      const lastAction = receipt.actions[receipt.actions.length - 1];
      if (lastAction?.type === ReceiptAction.ReceiptActionType.revalidated) {
        return fReceiptActionType(ReceiptAction.ReceiptActionType.revalidated);
      }

      const states = [
        ReceiptAction.ReceiptActionType.corrected,
        ReceiptAction.ReceiptActionType.correction,
        ReceiptAction.ReceiptActionType.replaced,
        ReceiptAction.ReceiptActionType.replacement,
      ];

      const actions = intersection(
        receipt.actions.map((a) => a.type),
        states
      );

      return fReceiptActionType(actions[0]);
    },
    [fReceiptActionType]
  );

  const fReceiptNotReplaced = useCallback((receipt?: Receipt.Receipt) => {
    if (!receipt || receipt.invalidated) return true;
    const states = [ReceiptAction.ReceiptActionType.corrected, ReceiptAction.ReceiptActionType.replaced];
    return (
      intersection(
        receipt.actions.map((a) => a.type),
        states
      ).length === 0
    );
  }, []);

  // Get the visible custom fields, with their indices
  const fVisibleCustomFields = useCallback(() => {
    if (org?.donorCustomFields) {
      return org.donorCustomFields
        .map((f, i) => ({ name: f.name, visible: f.visible, index: i }))
        .filter((f) => f.visible);
    } else return [];
  }, [org?.donorCustomFields]);

  return {
    isDateValid,
    isDateBetween,

    // formats
    dateFormat: org?.dateFormat || 'MMM dd, yyyy',
    timeFormat: org?.timeFormat || 'p',

    // dates
    getDateFormat,
    fDate,
    fLongDate,
    fToNow,
    fDateToDayEnd,
    fDateToDayStart,
    fDateToMonthStart,
    fDateToMonthEnd,
    fDateToYearStart,
    fDateToPreviousYearStart,
    fDateToYearEnd,
    fDateToPreviousYearEnd,
    fDateToPreviousYear,
    fDateToISO,
    fMonthYear,
    fShortDate,

    // numbers
    fCurrency,
    fUnCurrency,
    fPercent,
    fNumber,
    fShortenNumber,
    fData,

    // other
    fJoinWithConjunction,
    fAddress,
    fAddressLines,
    fFullName,
    fFullLegalName,
    fReversedName,
    fReceiptNumber,
    fReceiptActionType,
    fReceiptState,
    fReceiptNotReplaced,
    fVisibleCustomFields,
  };
};

export default useFormat;
