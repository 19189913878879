import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import Dialog from '@components/Dialog';
import FormProvider from '@components/hook-form/FormProvider';
import { RHFTextField, RHFDatePicker, RHFAutocomplete } from '@components/hook-form';
import { Grid, Stack, Typography, Button, CircularProgress } from '@mui/material';
import { useForm } from 'react-hook-form';
import { SummarySchemaForm, SummarySchemaResolver } from '@/schemas';
import useOrg from '@hooks/useOrg';
import useDonation from '@hooks/useDonation';
import useFormat from '@hooks/useFormat';
import DonationsTable from './steps/DonationsTable';
import useNavigate from '@hooks/useNavigate';
import PATHS from '@routes/paths';
import { Donation } from '@shared/types';
import { TColumn } from '@typedefs/app';
import ConfirmDialog from '@components/ConfirmDialog';
import { BankDepositType } from '@shared/types/bankDeposit';
import { parseDateTimezone } from '@redux/slices/donation';

const tableCols: TColumn[] = [
  {
    id: 'reversedName',
    type: 'string',
    label: 'Name',
    align: 'left',
    visible: true,
  },
  {
    id: 'date',
    type: 'date',
    label: 'Date',
    align: 'left',
    visible: true,
  },
  {
    id: 'amount',
    type: 'currency',
    label: 'Amount ($)',
    align: 'right',
    visible: true,
  },
  {
    id: 'category',
    label: 'Category',
    align: 'left',
    visible: true,
  },
  {
    id: 'paymentMethod',
    type: 'string',
    label: 'Payment Method',
    align: 'left',
    visible: true,
  },
];

export default function BankDepositEditDialog() {
  const { bankDepositId } = useParams();
  const {
    getBankDepositById,
    updateBankDeposit,
    deleteBankDeposit,
    isShowingClasses,
    bankDeposits,
    donations,
    cashCounts,
  } = useDonation();
  const { enqueueSnackbar } = useSnackbar();
  const { org } = useOrg();
  const navigate = useNavigate();
  const { fDateToISO } = useFormat();

  const [isSubmitting, setSubmitting] = useState(false);
  const [isConfirmingDelete, setConfirmingDelete] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [hasAdvantage, setHasAdvantage] = useState(false);

  const bankDeposit = getBankDepositById(bankDepositId);

  const associatedDonations = useMemo(
    () => donations.filter((d) => d.bankDepositId === bankDepositId),
    [bankDepositId, donations]
  );

  const hasCashCounts = useMemo(
    () =>
      bankDepositId ? cashCounts.some((count) => count.bankDepositId === bankDepositId) : false,
    [bankDepositId, cashCounts]
  );

  const selectedDonationIds = useMemo(
    () => associatedDonations.map((donation) => donation.id),
    [associatedDonations]
  );

  const bankAccounts = useMemo(() => {
    const uniqueAccounts = new Map<
      string,
      {
        name: string;
        accountNumber?: string;
      }
    >();

    bankDeposits.forEach((deposit) => {
      const key = deposit.bankAccount.toLowerCase();
      if (!uniqueAccounts.has(key)) {
        uniqueAccounts.set(key, {
          name: deposit.bankAccount,
          accountNumber: deposit.accountNumber,
        });
      }
    });

    return Array.from(uniqueAccounts.values()).sort((a, b) => a.name.localeCompare(b.name));
  }, [bankDeposits]);

  const methods = useForm({
    resolver: SummarySchemaResolver,
    defaultValues: {
      date: bankDeposit ? parseDateTimezone(bankDeposit.date) : new Date(),
      bankAccount: bankDeposit?.bankAccount || '',
      accountNumber: bankDeposit?.accountNumber || '',
      description: bankDeposit?.description || '',
      advantageAccount: bankDeposit?.advantageAccount || '',
      advantageClass: bankDeposit?.advantageClass || '',
    },
    mode: 'onChange',
  });

  const {
    watch,
    setValue,
    handleSubmit,
    formState: { isValid },
  } = methods;

  const watchedBankAccount = watch('bankAccount');

  useEffect(() => {
    setHasAdvantage(
      associatedDonations.some((donation: Donation.Donation) => donation.withAdvantage)
    );
  }, [associatedDonations]);

  useEffect(() => {
    if (!watchedBankAccount) return;

    const account = bankAccounts.find(
      (acc) => acc.name.toLowerCase() === watchedBankAccount.toLowerCase()
    );

    if (account?.accountNumber) {
      setValue('accountNumber', account.accountNumber);
    }
  }, [watchedBankAccount, bankAccounts, setValue]);

  const handleDelete = async () => {
    if (!org?.id || !bankDeposit) return;

    setDeleting(true);
    try {
      await deleteBankDeposit({
        orgId: org.id,
        bankDepositId: bankDeposit.id,
      });
      enqueueSnackbar('Bank Deposit Deleted Successfully!');
      handleClose();
    } catch (e) {
      enqueueSnackbar('Error Deleting Bank Deposit!', { variant: 'error' });
    } finally {
      setDeleting(false);
      setConfirmingDelete(false);
    }
  };

  const onSubmit = async (data: SummarySchemaForm) => {
    if (!org?.id || !bankDeposit) return;

    setSubmitting(true);
    try {
      await updateBankDeposit({
        bankDepositId: bankDeposit.id,
        update: {
          bankDeposit: {
            orgId: org.id,
            date: fDateToISO(data.date),
            bankAccount: data.bankAccount,
            accountNumber: data.accountNumber,
            description: data.description,
            advantageAccount: data.advantageAccount,
            advantageClass: data.advantageClass,
            type: BankDepositType.cash,
          },
          donationIds: selectedDonationIds,
        },
      });
      enqueueSnackbar('Bank Deposit Updated Successfully!');
      handleClose();
    } catch (e) {
      enqueueSnackbar('Error Updating Bank Deposit!', { variant: 'error' });
    } finally {
      setSubmitting(false);
    }
  };

  const handleClose = () => {
    navigate(PATHS.org.donations.bankDeposits.root);
  };

  if (!bankDeposit) return null;

  return (
    <>
      <Dialog
        title="Edit Bank Deposit"
        onClose={handleClose}
        isLoading={isSubmitting || isDeleting}
        maxWidth="xl"
        fullWidth
        contentProps={{ sx: { pt: 1, height: '70vh', overflow: 'auto' } }}
        actions={
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            sx={{ width: '100%' }}
          >
            {!hasCashCounts && (
              <Button
                color="error"
                onClick={() => setConfirmingDelete(true)}
                disabled={isSubmitting || isDeleting}
              >
                Delete
              </Button>
            )}
            <div style={{ flexGrow: 1 }} />
            <Button
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              disabled={isSubmitting || isDeleting || !isValid}
              endIcon={isSubmitting ? <CircularProgress size={20} color="inherit" /> : undefined}
            >
              Save
            </Button>
          </Stack>
        }
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <RHFAutocomplete
                  name="bankAccount"
                  label="Bank Name"
                  options={bankAccounts.map((acc) => acc.name)}
                  freeSolo
                  autoFocus
                  required
                  fullWidth
                  onInputChange={(event, value) => {
                    setValue('bankAccount', value, { shouldValidate: true });
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <RHFAutocomplete
                  name="accountNumber"
                  label="Account Number"
                  options={bankAccounts
                    .map((acc) => acc.accountNumber)
                    .filter((num): num is string => !!num)}
                  freeSolo
                  fullWidth
                  onInputChange={(event, value) => {
                    setValue('accountNumber', value, { shouldValidate: true });
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <RHFDatePicker name="date" label="Deposit Date" required fullWidth />
              </Grid>

              <Grid item xs={12} sm={6}>
                <RHFTextField name="description" label="Description" fullWidth />
              </Grid>

              {hasAdvantage && (
                <>
                  <Grid item xs={12} sm={6}>
                    <RHFTextField name="advantageAccount" label="Advantage Account" fullWidth />
                  </Grid>
                  {isShowingClasses && (
                    <Grid item xs={12} sm={6}>
                      <RHFTextField name="advantageClass" label="Advantage Class" fullWidth />
                    </Grid>
                  )}
                </>
              )}
            </Grid>

            <Stack spacing={3}>
              <Typography variant="subtitle1">Donations included</Typography>
              <DonationsTable
                donations={associatedDonations}
                selectedIds={selectedDonationIds}
                onSelectionChange={() => {}} // Disabled for edit mode
                columns={tableCols}
                readOnly
              />
            </Stack>
          </Stack>
        </FormProvider>
      </Dialog>

      <ConfirmDialog
        open={isConfirmingDelete}
        onClose={() => setConfirmingDelete(false)}
        onConfirm={handleDelete}
        loading={isDeleting}
      />
    </>
  );
}
