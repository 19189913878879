import { useCallback, useMemo } from 'react';
import { formatDistanceToNowStrict } from 'date-fns';

import { useSelector } from '@redux/store';
import * as fn from '@fire/functions';
import type { Stripe } from '@shared/types';

const useStripe = () => {
  // -------------------- data --------------------
  const { org } = useSelector((s) => s.org);

  // -------------------- effects --------------------
  const subscription = useMemo(() => {
    // org doesn't exist OR never purchased anything
    if (!org?.subscriptions?.donation) return undefined;
    return org.subscriptions.donation;
  }, [org]);

  const isFreeTrial = subscription?.subscriptionStatus === 'trialing';
  const trialRemaining = useMemo(() => {
    if (!isFreeTrial) return '';

    return formatDistanceToNowStrict(new Date(subscription.dateExpiration), {
      roundingMethod: 'ceil',
      unit: 'day',
    });
  }, [isFreeTrial, subscription]);
  const customerId = org?.subscriptions?.customerId;

  // -------------------- actions --------------------
  const getProducts = useCallback(async (payload: Stripe.GetPricingProductsReq) => {
    try {
      const {
        data: { products },
      } = await fn.getPricingProducts(payload);
      return products;
    } catch (e) {
      throw e;
    }
  }, []);

  const createPortalSession = useCallback(async (payload: Stripe.CreatePortalSessionReq) => {
    try {
      const {
        data: { sessionUrl },
      } = await fn.createPortalSession(payload);
      return sessionUrl;
    } catch (e) {
      throw e;
    }
  }, []);

  const createCheckoutSession = useCallback(async (payload: Stripe.CreateCheckoutSessionReq) => {
    try {
      const {
        data: { sessionUrl },
      } = await fn.createCheckoutSession(payload);
      return sessionUrl;
    } catch (e) {
      throw e;
    }
  }, []);

  // -------------------- hook --------------------
  return {
    customerId,
    subscription,
    isFreeTrial,
    trialRemaining,

    getProducts,
    createPortalSession,
    createCheckoutSession,
  };
};

export default useStripe;
