import { Category, PaymentMethod, Tag } from '@shared/types';
import { TColumn } from '@typedefs/app';
import { TDonorWithDonations } from '@typedefs/donation';

// ----------------------------------------------------------------------
export type DonorListItem = {
  readonly id: string;
  readonly _donor: TDonorWithDonations;
  readonly _tags: Tag.Tag[];
  readonly _categories: Category.Category[];
  readonly _paymentMethods: PaymentMethod.PaymentMethod[];
  readonly _donationsCount: number;
  readonly _donationsTotal: number;

  createdAt: string;
  name: string;
  reversedName: string;
  memberNumber?: string;
  email?: string;
  phone?: string;
  address: string;
  date: string;
  amount: string;
  donationsCount: string;
  tags: string;
  categories: string;
  paymentMethods: string;
  nonReceiptable: string;
  notes: string;
  custom1: string;
  custom2: string;
  custom3: string;
  custom4: string;
  custom5: string;
  custom6: string;
};

export type ArchivedDonorListItem = Pick<DonorListItem, 'id' | 'name'>;

// ----------------------------------------------------------------------
export type DonorListHeader =
  | 'archiveDonors'
  | 'createdAt'
  | 'date'
  | 'amount'
  | 'donationsCount'
  | 'name'
  | 'reversedName'
  | 'memberNumber'
  | 'email'
  | 'phone'
  | 'address'
  | 'tags'
  | 'categories'
  | 'paymentMethods'
  | 'nonReceiptable'
  | 'notes'
  | 'options'
  | 'custom1'
  | 'custom2'
  | 'custom3'
  | 'custom4'
  | 'custom5'
  | 'custom6';

export const DONOR_LIST_HEADERS: TColumn<DonorListHeader>[] = [
  // { id: 'id', type: 'string', label: 'ID', align: 'left', visible: false },
  {
    id: 'createdAt',
    type: 'date',
    label: 'Donor Created At',
    align: 'left',
    visible: false,
    filterable: true,
    searchable: true,
    pdfWidth: 15,
  },
  {
    id: 'date',
    type: 'date',
    label: 'Last Donation Date',
    align: 'left',
    visible: false,
    filterable: true,
    searchable: true,
    pdfWidth: 15,
  },
  {
    id: 'name',
    type: 'string',
    label: 'Donor Name',
    align: 'left',
    visible: true,
    filterable: true,
    searchable: true,
    pdfWidth: 20,
  },
  {
    id: 'reversedName',
    type: 'string',
    label: 'Donor Reversed Name',
    align: 'left',
    visible: false,
    filterable: true,
    searchable: true,
    pdfWidth: 20,
  },
  {
    id: 'memberNumber',
    type: 'string',
    label: 'Member/Env. #',
    align: 'right',
    visible: false,
    filterable: true,
    searchable: true,
    pdfWidth: 10,
  },
  {
    id: 'email',
    type: 'string',
    label: 'Email',
    align: 'left',
    visible: true,
    filterable: true,
    searchable: true,
    pdfWidth: 15,
  },
  {
    id: 'tags',
    label: 'Tags',
    align: 'left',
    visible: true,
    filterable: false,
    searchable: true,
    pdfWidth: 15,
  },
  {
    id: 'donationsCount',
    type: 'number',
    label: 'Donation Count (#)',
    align: 'right',
    visible: true,
    filterable: true,
    searchable: true,
    pdfWidth: 15,
  },
  {
    id: 'amount',
    type: 'currency',
    label: 'Donation Total ($)',
    align: 'right',
    visible: true,
    filterable: true,
    searchable: true,
    pdfWidth: 15,
  },
  {
    id: 'categories',
    label: 'Categories',
    align: 'left',
    visible: false,
    filterable: false,
    searchable: true,
    pdfWidth: 20,
  },
  {
    id: 'phone',
    type: 'string',
    label: 'Phone',
    align: 'left',
    visible: false,
    filterable: false,
    searchable: true,
    pdfWidth: 15,
  },
  {
    id: 'address',
    type: 'address',
    label: 'Address',
    align: 'left',
    visible: false,
    filterable: true,
    searchable: true,
    pdfWidth: 20,
  },
  {
    id: 'paymentMethods',
    type: 'string',
    label: 'Payment Method',
    align: 'left',
    visible: false,
    filterable: true,
    searchable: true,
    pdfWidth: 10,
  },
  {
    id: 'nonReceiptable',
    type: 'boolean',
    label: 'Non-Receiptable',
    align: 'left',
    visible: false,
    filterable: true,
    searchable: true,
    pdfWidth: 10,
  },
  {
    id: 'notes',
    type: 'string',
    label: 'Donor Notes',
    align: 'left',
    visible: false,
    filterable: true,
    searchable: true,
    pdfWidth: 20,
  },
  {
    id: 'custom1',
    type: 'string',
    label: 'Custom 1',
    align: 'left',
    visible: false,
    filterable: true,
    searchable: true,
    pdfWidth: 20,
  },
  {
    id: 'custom2',
    type: 'string',
    label: 'Custom 2',
    align: 'left',
    visible: false,
    filterable: true,
    searchable: true,
    pdfWidth: 20,
  },
  {
    id: 'custom3',
    type: 'string',
    label: 'Custom 3',
    align: 'left',
    visible: false,
    filterable: true,
    searchable: true,
    pdfWidth: 20,
  },
  {
    id: 'custom4',
    type: 'string',
    label: 'Custom 4',
    align: 'left',
    visible: false,
    filterable: true,
    searchable: true,
    pdfWidth: 20,
  },
  {
    id: 'custom5',
    type: 'string',
    label: 'Custom 5',
    align: 'left',
    visible: false,
    filterable: true,
    searchable: true,
    pdfWidth: 20,
  },
  {
    id: 'custom6',
    type: 'string',
    label: 'Custom 6',
    align: 'left',
    visible: false,
    filterable: true,
    searchable: true,
    pdfWidth: 20,
  },
  // { id: 'options', label: '', align: 'left', visible: false, internal: true },
];
