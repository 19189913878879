import { useMemo, useState } from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  PDFViewer,
  BlobProvider,
  Document,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { filter, groupBy, orderBy, sortBy, sumBy } from 'lodash';

import * as analytics from '@fire/analytics';
import PATHS from '@routes/paths';
import useFormat from '@hooks/useFormat';
import useOrg from '@hooks/useOrg';
import useDonation from '@hooks/useDonation';
import useNavigate from '@hooks/useNavigate';
import { PageOrientation, PageSize } from '@typedefs/app';

import Dialog from '@components/Dialog';
import { PdfTable, PdfTableData, PdfTableHeaderProps, PdfTableSummaryField } from '@components/pdf';
import { ReportProps } from '@pages/reports/ReportCard';
import ReportLayout from '../../ReportLayout';
import ReportHeader from '@pages/reports/ReportHeader';
import { BankDeposit, Organization, Donation } from '@shared/types';
import {
  AvailableSortOrder,
  SortOrder,
  sortByOrders,
  orderDescription,
} from '@pages/reports/reportSorting';
import SortDialog from '@pages/reports/Dialogs/Sort';
import PdfCsvMenu from '@components/pdf/PdfCsvMenu';

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 32,
    fontFamily: 'Lato',
    fontSize: 10,
  },
  summaryTableHeader: {
    paddingTop: 10,
    fontWeight: 'bold',
  },
  tableRow: {
    flexDirection: 'row',
    minHeight: 18,
  },
  tableRowItem: {
    padding: '2px 4px 0',
    width: '50%',
  },
});

interface BankDepositDetailsProps extends ReportProps {
  bdId?: string;
  onClose?: () => void;
}

// ----------------------------------------------------------------------
export default function Report({ id, name, description, bdId, onClose }: BankDepositDetailsProps) {
  const navigate = useNavigate();
  const { fDate, fLongDate, fCurrency, fReversedName } = useFormat();
  const {
    bankDeposits,
    donors,
    donations,
    getCategoryById,
    getPaymentMethod,
    getPaymentMethodById,
    isBankDepositQuickBooks,
  } = useDonation();
  const { org } = useOrg();
  const accountingProgram =
    org?.bankDepositSettings?.type === Organization.BankDepositAccountingProgram.Other
      ? 'Accounting Program'
      : org?.bankDepositSettings?.type;

  // ---------- form state
  const [orientation, setOrientation] = useState<PageOrientation>('portrait');
  const [size, setSize] = useState<PageSize>('letter');
  const [sortOrders, setSortOrders] = useState<SortOrder[]>([{ availablesIndex: 1 }]);
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);
  const [bankDepositId, setBankDepositId] = useState<String | undefined>(bdId);

  // ---------- memo

  // Available sort oders, only apply to the details table in this composite report.
  const availableSortOrders: AvailableSortOrder[] = useMemo(
    () => [
      { label: 'Name', field: (d) => d.name.toUpperCase() },
      { label: 'Date', field: 'date' },
      { label: 'Amount', field: 'amount' },
      { label: 'Category', field: (d) => d.category.toUpperCase() },
      { label: 'Payment Method', field: (d) => d.paymentMethod.toUpperCase() },
    ],
    []
  );

  const orderedBankDeposits: BankDeposit.BankDeposit[] = useMemo(() => {
    const filtered = bankDeposits.filter(
      (deposit) => deposit.type === BankDeposit.BankDepositType.cash
    );
    const ordered = orderBy(filtered, 'date', 'desc');
    if (!bankDepositId && ordered.length) setBankDepositId(ordered[0].id);
    return ordered;
  }, [bankDepositId, bankDeposits]);

  const bankDeposit: BankDeposit.BankDeposit | undefined = useMemo(() => {
    if (bankDepositId) {
      return orderedBankDeposits.find((b) => b.id === bankDepositId);
    } else {
      // use the latest one
      return orderedBankDeposits[0] || undefined;
    }
  }, [bankDepositId, orderedBankDeposits]);

  const [donationsForDeposit, totalAmount]: [Donation.Donation[], number] = useMemo(() => {
    if (!bankDepositId) {
      return [[], 0];
    }
    const filtered = filter(donations, (d) => d.bankDepositId === bankDepositId);
    return [filtered, sumBy(filtered, 'amount')];
  }, [donations, bankDepositId]);

  const detailsTableData: PdfTableData = useMemo(() => {
    let header: PdfTableHeaderProps[] = [
      { name: 'Name', width: 30 },
      { name: 'Date', width: 12 },
      { name: 'Amount ($)', width: 12, align: 'right' },
      { name: 'Category', width: 20 },
      { name: 'Payment Method', width: 24 },
    ];

    const year = new Date(bankDeposit?.date || '').getFullYear();
    const itemsUnsorted = donationsForDeposit.map((d) => {
      const donor = donors.find((don) => don.id === d.donorId);
      return {
        name: fReversedName(donor, year),
        date: new Date(d.date),
        amount: d.amount,
        category: getCategoryById(d.categoryId)?.name || '',
        paymentMethod: getPaymentMethod(d.paymentMethodId, d.paymentInfo),
      };
    });
    const sorted = sortByOrders(itemsUnsorted, sortOrders, availableSortOrders);
    const items = sorted.map((d) => [
      d.name,
      fDate(d.date),
      fCurrency(d.amount),
      d.category,
      d.paymentMethod,
    ]);
    const summary: PdfTableSummaryField[] = [
      { columns: 1, text: `Totals: ${items.length} donations` },
      { columns: 2, text: `${fCurrency(totalAmount)}` },
    ];

    return { header, items, summary };
  }, [
    bankDeposit?.date,
    donationsForDeposit,
    sortOrders,
    availableSortOrders,
    fCurrency,
    totalAmount,
    donors,
    fReversedName,
    getCategoryById,
    getPaymentMethod,
    fDate,
  ]);

  const categoryTableData: PdfTableData = useMemo(() => {
    const header: PdfTableHeaderProps[] = [
      { name: 'Category', width: 50 },
      { name: 'Amount ($)', width: 20, align: 'right' },
    ];

    const grouped = groupBy(donationsForDeposit, 'categoryId');
    const itemsUnsorted = Object.keys(grouped).map((categoryId) => {
      const category = getCategoryById(categoryId);
      const categoryTotal = sumBy(grouped[categoryId], 'amount');
      return [category?.name, fCurrency(categoryTotal)] as string[];
    });
    const items = sortBy(itemsUnsorted, (item) => item[0].toUpperCase());

    const summary: PdfTableSummaryField[] = [
      { columns: 1, text: 'Total:' },
      { columns: 1, text: `${fCurrency(totalAmount)}` },
    ];

    return { header, items, summary };
  }, [donationsForDeposit, fCurrency, totalAmount, getCategoryById]);

  const transactionTableData: PdfTableData = useMemo(() => {
    // the Class (if using QB) is the 2nd element, this inserts it into an array
    const insertClass = (arr: any[], value: any) => {
      if (isBankDepositQuickBooks) arr.splice(1, 0, value);
    };

    let header: PdfTableHeaderProps[] = [
      { name: 'Accounting Account', width: 32 },
      { name: 'Deposit', width: 16, align: 'right' },
      { name: 'Splits', width: 16, align: 'right' },
    ];
    insertClass(header, { name: 'Accounting Class', width: 32 });

    const grouped = groupBy(donationsForDeposit, 'categoryId');
    const advantage = sumBy(donationsForDeposit, (d) => d.amountAdvantage || 0);
    let items = [[]] as string[][];
    if (bankDeposit) {
      items = [[bankDeposit?.bankAccount || '', fCurrency(totalAmount), '']];
      if (advantage > 0) {
        const row = [bankDeposit?.advantageAccount || '', '', fCurrency(advantage)];
        insertClass(row, bankDeposit?.advantageClass || '');
        items.push(row);
      }
      const categoryItems = Object.keys(grouped).map((categoryId) => {
        const category = getCategoryById(categoryId);
        const categoryTotal = sumBy(
          grouped[categoryId],
          (d) => d.amount - (d.amountAdvantage || 0)
        );
        let row = [
          category?.accountingAccount || category?.name,
          '',
          fCurrency(categoryTotal),
        ] as string[];
        insertClass(row, category?.accountingClass || '');
        return row;
      });
      items = items.concat(sortBy(categoryItems, (item) => item[0].toUpperCase()));
    }
    return { header, items };
  }, [
    donationsForDeposit,
    bankDeposit,
    isBankDepositQuickBooks,
    fCurrency,
    totalAmount,
    getCategoryById,
  ]);

  const paymentMethodTableData: PdfTableData = useMemo(() => {
    const header: PdfTableHeaderProps[] = [
      { name: 'Payment Method', width: 50 },
      { name: 'Amount ($)', width: 20, align: 'right' },
    ];

    const grouped = groupBy(donationsForDeposit, 'paymentMethodId');
    const itemsUnsorted = Object.keys(grouped).map((paymentMethodId) => {
      const paymentMethodLabel = getPaymentMethodById(paymentMethodId)?.name || '';
      const methodTotal = sumBy(grouped[paymentMethodId], 'amount');
      return [paymentMethodLabel, fCurrency(methodTotal)] as string[];
    });
    const items = sortBy(itemsUnsorted, (item) => item[0].toUpperCase());

    const summary: PdfTableSummaryField[] = [
      { columns: 1, text: 'Total:' },
      { columns: 1, text: `${fCurrency(totalAmount)}` },
    ];

    return { header, items, summary };
  }, [donationsForDeposit, fCurrency, totalAmount, getPaymentMethodById]);

  // --------------------------------------------------
  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      navigate(PATHS.org.reports.root);
    }
  };

  const handleOpenMenu = (e: React.MouseEvent<HTMLElement>, blob: Blob) => {
    setPdfBlob(blob);
    e.preventDefault();
    e.stopPropagation();
    setOpenMenuActions(e.currentTarget);
  };

  const { width, height } = PageSize[size];

  // --------------------------------------------------
  // Because this report has multiple PdfTables in it, have to replicate
  // the code from ReportDocument.
  const ReportDoc = useMemo(() => {
    const pageSize =
      orientation === 'landscape' ? { width: height, height: width } : { width, height };
    const longDate = !!bankDeposit?.date ? fLongDate(new Date(bankDeposit.date)) : '';
    return (
      <Document
        title={`${name} - ${fLongDate(new Date())}`}
        subject={description}
        author="Software4Nonprofits"
        creator="s4np web-app"
        producer="s4np web-app"
        pdfVersion="1.7"

        // onRender={(blob) => console.log('doc render: ', blob)}
      >
        {/* US letter size is different than A4 iso size */}
        {/* below is calculated using 72dpi */}
        <Page style={styles.page} size={pageSize} wrap>
          <ReportHeader
            title={`Bank Deposit made on: ${longDate}  To: ${bankDeposit?.bankAccount || ''}${bankDeposit?.accountNumber ? `, Account # ${bankDeposit?.accountNumber}` : ''}`}
            filterDescription={`${bankDeposit?.description ? `Description: ${bankDeposit?.description}` : ''}`}
            dateReport={new Date()}
            dateFrom={null} // forces no date range display
            orderBy={
              sortOrders.length === 1 && sortOrders[0].availablesIndex === 0
                ? ''
                : orderDescription(sortOrders, availableSortOrders)
            }
            orgName={org!.name}
          />
          <PdfTable data={detailsTableData} />
          <View style={styles.tableRow}>
            <View style={styles.tableRowItem}>
              <Text style={styles.summaryTableHeader}>Summary by Category</Text>
              <PdfTable data={categoryTableData} />
            </View>
            <View style={styles.tableRowItem}>
              <Text style={styles.summaryTableHeader}>Summary by Payment Method</Text>
              <PdfTable data={paymentMethodTableData} />
            </View>
          </View>
          <Text style={styles.summaryTableHeader}>{`Transaction for ${accountingProgram}`}</Text>
          <PdfTable data={transactionTableData} />
        </Page>
      </Document>
    );
  }, [
    orientation,
    height,
    width,
    name,
    fLongDate,
    description,
    bankDeposit?.date,
    bankDeposit?.bankAccount,
    bankDeposit?.accountNumber,
    bankDeposit?.description,
    sortOrders,
    availableSortOrders,
    org,
    detailsTableData,
    categoryTableData,
    paymentMethodTableData,
    accountingProgram,
    transactionTableData,
  ]);

  const tableDatas: PdfTableData[] = useMemo(
    () => [detailsTableData, categoryTableData, paymentMethodTableData, transactionTableData],
    [categoryTableData, detailsTableData, paymentMethodTableData, transactionTableData]
  );

  const formatForSelect = (bd: BankDeposit.BankDeposit): string =>
    `${fDate(bd.date)} ${bd.bankAccount}${bd.accountNumber ? ` ${bd.accountNumber}` : ''}${bd.description ? ` ${bd.description}` : ''}`;

  return (
    <Dialog
      title="Bank Deposit Details Report"
      maxWidth="lg"
      onClose={handleClose}
      actions={
        <BlobProvider document={ReportDoc}>
          {({ blob, url, loading, error }) => (
            <LoadingButton
              size="large"
              variant="contained"
              disabled={!blob || !!error || !bankDepositId}
              loading={loading}
              onClick={blob ? (e) => handleOpenMenu(e, blob) : undefined}
            >
              Download
            </LoadingButton>
          )}
        </BlobProvider>
      }
    >
      <PdfCsvMenu
        tableData={tableDatas}
        pdfBlob={pdfBlob}
        basename={'bank_deposit_details'}
        openMenu={openMenu}
        setOpenMenu={setOpenMenuActions}
        handleClose={handleClose}
        analyticsPdfFn={() => analytics.donation.reportDownloadPDF(id)}
        analyticsCsvFn={() => analytics.donation.reportDownloadCSV(id)}
      />
      <Grid container spacing={3} alignItems="flex-start" justifyContent="center">
        <Grid item xs={12} md={3}>
          <Stack spacing={2} direction="column" width={'100%'}>
            <ReportLayout
              orientation={orientation}
              setOrientation={setOrientation}
              size={size}
              setSize={setSize}
            />
            <SortDialog
              availableOrders={availableSortOrders}
              currentOrders={sortOrders}
              setOrders={setSortOrders}
              sortButtonText="Sort Details Table"
            />
            <Stack spacing={1} direction="column" width={'100%'}>
              <FormControl>
                <InputLabel id="select-deposit">Select Deposit</InputLabel>
                <Select
                  value={bankDeposit?.id}
                  size="small"
                  labelId="select-deposit"
                  label="Select Deposit"
                  onChange={(e) => setBankDepositId(e.target.value)}
                  disabled={!!bdId}
                >
                  {orderedBankDeposits.length > 0 ? (
                    orderedBankDeposits.map((bd) => (
                      <MenuItem key={bd.id} value={bd.id}>
                        {formatForSelect(bd)}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled value="">
                      No deposits found
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={12} md={9}>
          <PDFViewer showToolbar={false} width="100%" style={{ height: '50vh' }}>
            {ReportDoc}
          </PDFViewer>
        </Grid>
      </Grid>
    </Dialog>
  );
}
