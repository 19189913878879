import { Address, Meta } from '../index';
export * from './requests';

// ----------------------------------------------------------------------
export type Donor = {
  readonly id: string;
  readonly _meta: Meta;

  // internal fields
  readonly orgId: string;

  // donor descriptions
  readonly firstName: string;
  readonly middleName: string;
  readonly lastName: string;
  readonly email?: string;
  readonly phone?: string;
  readonly address?: Address;

  readonly type: DonorType;
  readonly organization?: string;

  readonly nonReceiptable?: boolean; // donations under this can't be issued in receipt
  readonly tagIds: string[];
  readonly notes: string; // TODO: should become a separate col so we can have items rather than single box?

  readonly memberNumbers: { [year: number]: number };
  readonly customFields?: string[];

  // V1 fields for migration from V2->V1
  readonly v1DonorNum?: number;
};

export enum DonorType {
  individual = 'individual',
  business = 'business',
}

export type ArchivedDonorListItem = Pick<Donor, 'id' | 'firstName' | 'lastName'>;

export type GetArchivedDonorsReq = {
  readonly orgId: string;
};

export type GetArchivedDonorsRes = {
  readonly donors: ArchivedDonorListItem[];
};

export type UnarchiveDonorsReq = {
  readonly orgId: string;
  readonly donorIds: string[];
};
