import { useEffect, useMemo } from 'react';
import { Alert, MenuItem, Stack, TextareaAutosize, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';

import useOrg from '@hooks/useOrg';
import useAuth from '@hooks/useAuth';
import useDonation from '@hooks/useDonation';
import { FeedbackTopics } from '@typedefs/org';
import { feedbackSchemaResolver, FeedbackSchemaForm } from '@/schemas';
import { RHFSelect, RHFTextField } from '@components/hook-form';
import Dialog from '@components/Dialog';
import Iconify from '@components/Iconify';

// ----------------------------------------------------------------------
type Props = {
  readonly open: boolean;
  readonly setOpen: (open: boolean) => void;
};

// ----------------------------------------------------------------------
export default function FeedbackDialog({ open, setOpen }: Props) {
  const { user } = useAuth();
  const { donors, donations } = useDonation();
  const { org, submitFeedback } = useOrg();
  const { enqueueSnackbar } = useSnackbar();

  // --------------- effects ---------------
  const defaultValues: FeedbackSchemaForm = useMemo(
    () => ({
      topic: '',
      text: '',
    }),
    []
  );

  // --------------- form ---------------
  const methods = useForm<FeedbackSchemaForm>({
    resolver: feedbackSchemaResolver,
    defaultValues,
  });

  const {
    setError,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid, isSubmitting },
  } = methods;

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open, reset]);

  // --------------- actions ---------------
  const onSubmit = async (data: FeedbackSchemaForm) => {
    if (!user || !org) {
      setError('afterSubmit', { message: 'Internal error, contact support' });
      return null;
    }

    try {
      await submitFeedback({
        user,
        org,
        donorCount: donors.length,
        donationCount: donations.length,
        text: data.text,
        context: FeedbackTopics.find((t) => t.value === data.topic)?.label || 'Unknown',
      });
      enqueueSnackbar(`Feedback submitted!`);
      setOpen(false);
    } catch (error) {
      setError('afterSubmit', { ...error, message: error.message });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={setOpen}
      title="Support"
      isDirty={isDirty}
      isLoading={isSubmitting}
      methods={methods}
      maxWidth="sm"
      actions={
        <LoadingButton
          size="large"
          variant="contained"
          loading={isSubmitting}
          disabled={!isValid}
          onClick={handleSubmit(onSubmit)}
        >
          Submit
        </LoadingButton>
      }
    >
      <Stack spacing={1} sx={{ margin: '0 auto' }}>
        <Typography marginBottom={1}>
          Review our{' '}
          <a
            href="https://docs.google.com/document/d/1aS-yZYhVuszlTFWwxP_gsLK4rym232WW"
            target="_blank"
            rel="noreferrer"
          >
            Knowledge Base <Iconify icon="ooui:new-window-ltr" />
          </a>{' '}
          for the fastest answers.
        </Typography>
        <Typography marginBottom={1}>
          Need something else? Send us a note below.
          <br />
        </Typography>
        <RHFSelect fullWidth name="topic" label="Topic" required>
          <MenuItem value="" disabled>
            <Typography variant="caption">Select Topic</Typography>
          </MenuItem>
          {FeedbackTopics.map((p) => (
            <MenuItem key={p.value} value={p.value}>
              {p.label}
            </MenuItem>
          ))}
        </RHFSelect>

        <RHFTextField
          name="text"
          type="text"
          label="Detailed description of your request or issue"
          placeholder="Please describe your request or issue in as much detail as possible so we can get back to you quickly with helpful solutions."
          disabled={isSubmitting}
          fullWidth
          required
          InputProps={{
            inputComponent: TextareaAutosize,
          }}
          inputProps={{
            minRows: 4,
            rows: 4,
            maxRows: 6,
          }}
        />

        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
      </Stack>
    </Dialog>
  );
}
