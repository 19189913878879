import { TDonorWithDonations } from '@typedefs/donation';
import { Organization, RecurringDonation, Receipt } from '@shared/types';

type Props = {
  donor: TDonorWithDonations;
  org: Organization.Organization;
  recurringDonations: RecurringDonation.RecurringDonation[];
  receipts: Receipt.Receipt[];
}

export const checkCanArchiveDonor = ({ donor, org, recurringDonations, receipts }: Props): boolean => {
  if (!donor || !org) return false;
  let yearStartDate: Date | undefined;

  const subscriptionEndDate = org.subscriptions?.donation?.dateExpiration;

  if (!subscriptionEndDate) {
    // For free plan users
    yearStartDate = new Date();
    yearStartDate.setFullYear(yearStartDate.getFullYear() - 1);
    yearStartDate.setMonth(0);
    yearStartDate.setDate(1);
  } else {
    // For subscription plan users
    yearStartDate = new Date(subscriptionEndDate);
    yearStartDate.setFullYear(yearStartDate.getFullYear() - 1);
  }

  const hasActiveDonations = donor.donations.some((d) => {
    const donationDate = typeof d.date === 'string' ? new Date(d.date) : d.date;
    return yearStartDate && donationDate >= yearStartDate && !d._meta?.archived;
  });

  const hasActiveRecurring = recurringDonations.some(
    (rd) => rd.donorId === donor.id && !rd._meta?.archived
  );

  const hasActiveReceipts = receipts.some(
    (r) => r.donorId === donor.id && r.year === yearStartDate?.getFullYear() && !r._meta?.archived
  );

  return !(hasActiveDonations || hasActiveRecurring || hasActiveReceipts);
};