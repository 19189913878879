import { useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router';
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';

import useNavigate from '@hooks/useNavigate';
import PATHS, { getPath } from '@routes/paths';
import useTable, { emptyRows } from '@hooks/useTable';
import { TColumn, TColumnFilter } from '@typedefs/app';
import { donorsViewCacheId } from '@typedefs/donation';
import useDonation from '@hooks/useDonation';

import Scrollbar from '@components/Scrollbar';
import { TableEmptyRows, TableHeadCustom, TableNoData } from '@components/table';
import Toolbar from '../components/Toolbar';
import { DonorsOutletContext } from '../useContext';
import Actions from '../Actions';
import {
  ArchivedDonorListItem,
  DONOR_LIST_HEADERS,
  DonorListHeader,
  DonorListItem,
} from './config';
import useData from './useData';
import Row from './Row';
import useOrg from '@hooks/useOrg';
import { checkCanArchiveDonor } from '@utils/canArchiveDonor';
import getPage from '@utils/getPage';
import Iconify from '@components/Iconify';
import ConfirmDialog from '@components/ConfirmDialog';
import { Tag, Category, View } from '@shared/types';
import * as fn from '@fire/functions';
import { enqueueSnackbar } from 'notistack';
import { pluralize } from '@utils/pluralize';

// ----------------------------------------------------------------------
type Props = { cachedView?: View.View; clearCache: VoidFunction };
export type ArchivedStateType = {
  isFetching: boolean;
  data: ArchivedDonorListItem[];
  listArchivedMode: boolean;
  bulkArchiveMode: boolean;
  selectedIds: string[];
  isConfirming: boolean;
  isProcessing: boolean;
};
// ----------------------------------------------------------------------
export default function DonorList({ cachedView, clearCache }: Props) {
  const { org } = useOrg();
  const navigate = useNavigate();
  const {
    getTagsFromIds,
    getCategoriesFromIds,
    getDonorCustomField,
    recurringDonations,
    receipts,
    archiveDonor,
  } = useDonation();

  const {
    columns,
    dateFrom,
    dateTo,
    order,
    orderBy,
    page,
    rowsPerPage,
    filters,
    onChangePage,
    onChangeRowsPerPage,
    onSort,
    setColumns,
    setDateFrom,
    setDateTo,
    setPage,
    setFilters,
  } = useTable<DonorListHeader>(
    {
      defaultRowsPerPage: 25,
      defaultColumns: DONOR_LIST_HEADERS,
      defaultOrderBy: 'name',
      defaultDateFrom: undefined,
      defaultDateTo: undefined,
    },
    donorsViewCacheId
  );

  const [archivedState, setArchivedState] = useState<ArchivedStateType>({
    isFetching: false,
    data: [],
    listArchivedMode: false,
    bulkArchiveMode: false,
    selectedIds: [],
    isConfirming: false,
    isProcessing: false,
  });

  const [search, setSearch] = useState('');
  const [filterTags, setFilterTags] = useState<Tag.Tag[]>(() => {
    const cachedTags = localStorage.getItem(donorsViewCacheId + '-tags');
    const tags = cachedTags ? JSON.parse(cachedTags) : [];
    return tags;
  });
  const [filterCategories, setFilterCategories] = useState<Category.Category[]>(() => {
    const cachedCats = localStorage.getItem(donorsViewCacheId + '-categories');
    const categories = cachedCats ? JSON.parse(cachedCats) : [];
    return categories;
  });

  useEffect(() => {
    if (cachedView && cachedView?.type === 'donor') {
      const columns = DONOR_LIST_HEADERS.map((h) => ({
        ...h,
        visible: cachedView.config.columnIds.includes(h.id),
      }));
      setColumns(columns);
      onSort(cachedView.config.orderBy as DonorListHeader, cachedView.config.order);
      setFilters(cachedView.config.filters as TColumnFilter[]);
      handleFilterTagChange(getTagsFromIds(cachedView.config.tagIds));
      handleFilterCatChange(getCategoriesFromIds(cachedView.config.categoryIds));
      clearCache();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cachedView]);

  // return the custom field based on an id such as "custom1" ... "custom6"
  const getDonorCustomFieldById = (id: string) => getDonorCustomField(parseInt(id.charAt(6)));

  useEffect(() => {
    setColumns(
      columns.map((c) => {
        // first handle the more complex custom fields
        if (c.id.startsWith('custom')) {
          const field = getDonorCustomFieldById(c.id);
          return { ...c, disabled: !field.visible, label: field.name };
        }
        // all other fields
        return c.id === 'memberNumber' ? { ...c, disabled: !org?.memberNumbers } : c;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [org?.memberNumbers, org?.donorCustomFields]);

  const currentColumns = useMemo(() => {
    const checkboxColumn: TColumn<DonorListHeader> = {
      id: 'archiveDonors',
      label: '',
      align: 'left',
      width: 48,
      minWidth: 48,
      visible: true,
      type: 'checkbox',
    };

    const nameColumn = columns.find((c) => c.id === 'name')!;
    if (archivedState.listArchivedMode) {
      return [checkboxColumn, nameColumn];
    }

    const withoutArchiveDonors = columns.filter((c) => c.id !== 'archiveDonors');
    return archivedState.bulkArchiveMode
      ? [checkboxColumn, ...withoutArchiveDonors]
      : withoutArchiveDonors;
  }, [columns, archivedState.bulkArchiveMode, archivedState.listArchivedMode]);

  const handleSearchChange = (search: string) => {
    setPage(0);
    setSearch(search);
  };

  const handleFilterTagChange = (newValue: Tag.Tag[]) => {
    setFilterTags(newValue);
    localStorage.setItem(donorsViewCacheId + '-tags', JSON.stringify(newValue));
  };

  const handleFilterCatChange = (newValue: Category.Category[]) => {
    setFilterCategories(newValue);
    localStorage.setItem(donorsViewCacheId + '-categories', JSON.stringify(newValue));
  };

  const handleEdit = (donorId: string) => {
    navigate(getPath(PATHS.org.donors.donor.edit, { donorId }));
  };

  // ----- FILTERING -------
  const data = useData({
    columns,
    dateFrom,
    dateTo,
    order,
    orderBy,
    search,

    filters: filters as TColumnFilter<DonorListHeader>[],
    filterTags,
    filterCategories,
  });

  const outletContext: DonorsOutletContext = {
    type: 'donor',
    data,

    columns,
    dateFrom,
    dateTo,
    order,
    orderBy: DONOR_LIST_HEADERS.find((h) => h.id === orderBy) || DONOR_LIST_HEADERS[2],

    filterCategories,
    filterTags,
    filters,
  };

  const isDonorSelectable = useCallback(
    (donor: DonorListItem) => {
      if (archivedState.listArchivedMode) {
        return true;
      }
      return checkCanArchiveDonor({
        donor: donor._donor,
        org: org!,
        recurringDonations: recurringDonations,
        receipts: receipts,
      });
    },
    [org, recurringDonations, receipts, archivedState.listArchivedMode]
  );

  const currentPageDonors = useMemo(() => {
    if (archivedState.listArchivedMode) {
      return getPage(archivedState.data, page, rowsPerPage);
    }
    return getPage(data, page, rowsPerPage);
  }, [data, page, rowsPerPage, archivedState.data, archivedState.listArchivedMode]);

  const selectableDonorsOnCurrentPage = useMemo(() => {
    if (archivedState.listArchivedMode) {
      return archivedState.data;
    } else {
      return currentPageDonors.filter((donor: ArchivedDonorListItem) =>
        isDonorSelectable(donor as DonorListItem)
      );
    }
  }, [archivedState.data, currentPageDonors, isDonorSelectable, archivedState.listArchivedMode]);

  const handleSelectAll = useCallback(
    (checked: boolean) => {
      const currentPageIds = new Set(currentPageDonors.map((donor) => donor.id));

      if (checked) {
        const selectableIds = new Set(selectableDonorsOnCurrentPage.map((donor) => donor.id));
        setArchivedState((prev) => ({
          ...prev,
          selectedIds: [...new Set([...prev.selectedIds, ...selectableIds])],
        }));
      } else {
        setArchivedState((prev) => ({
          ...prev,
          selectedIds: prev.selectedIds.filter((id) => !currentPageIds.has(id)),
        }));
      }
    },
    [currentPageDonors, selectableDonorsOnCurrentPage]
  );

  const archiveDonorsButtonStyle = {
    pointerEvents: archivedState.selectedIds.length === 0 ? 'none' : 'auto',
    opacity: archivedState.selectedIds.length === 0 ? 0.5 : 1,
    '&:hover': {
      backgroundColor: archivedState.selectedIds.length === 0 ? 'info.main' : undefined,
      cursor: archivedState.selectedIds.length === 0 ? 'not-allowed' : 'pointer',
    },
  };

  async function handleUnarchiveDonors() {
    try {
      await fn.donorUnarchive({ orgId: org!.id, donorIds: archivedState.selectedIds });
      setArchivedState((prev) => ({
        ...prev,
        data: prev.data.filter((donor) => !prev.selectedIds.includes(donor.id)),
      }));
      enqueueSnackbar(
        `You have successfully unarchived ${pluralize(archivedState.selectedIds.length, 'donor')}`,
        { variant: 'success' }
      );
    } catch (error) {
      enqueueSnackbar(
        `An error occurred while unarchiving donor(s), please try again. If the error continues, please contact support.`,
        { variant: 'error' }
      );
    } finally {
      resetArchiveStates();
    }
  }

  async function handleArchiveDonors() {
    try {
      const res = await archiveDonor({ orgId: org!.id, donorIds: archivedState.selectedIds });

      if (res.successCount > 0) {
        const successMessage = `Successfully archived ${pluralize(res.successCount, 'donor')}`;
        const failMessage = res.failedCount > 0 ? ` (${res.failedCount} failed)` : '';
        enqueueSnackbar(successMessage + failMessage, { variant: 'success' });
      } else if (res.failedCount > 0) {
        enqueueSnackbar(`Failed to archive ${pluralize(res.failedCount, 'donor')}.`, {
          variant: 'error',
        });
      }
    } catch (error) {
      enqueueSnackbar(
        `An error occurred while archiving donor(s), please try again. If the error continues, please contact support.`,
        { variant: 'error' }
      );
    } finally {
      resetArchiveStates();
    }
  }

  const resetArchiveStates = () => {
    setArchivedState((prev) => ({
      ...prev,
      isProcessing: false,
      isConfirming: false,
      bulkArchiveMode: false,
      listArchivedMode: false,
      selectedIds: [],
    }));
  };

  const handleDonors = async () => {
    setArchivedState((prev) => ({ ...prev, isProcessing: true }));
    if (archivedState.listArchivedMode) {
      await handleUnarchiveDonors();
    } else {
      await handleArchiveDonors();
    }
  };

  const archiveDonorConfirmationText = useMemo(() => {
    let confirmationText = '';
    if (archivedState.listArchivedMode) {
      confirmationText = `Are you sure you want to unarchive ${archivedState.selectedIds.length} donor(s)?`;
      confirmationText +=
        ' This will make them visible again in the donor list, and unarchive the associated data.';
      confirmationText +=
        '\n\nPlease note that by doing this, these donors will be reactivated and counted toward your account.';
    } else {
      confirmationText = `Are you sure you want to archive ${archivedState.selectedIds.length} donor(s)?`;
      confirmationText +=
        '\nThis action will remove them from the donor list and archive their associated data.';
      confirmationText +=
        '\n\nPlease note that once archived, you will not be able to perform any actions on these donors.';
    }
    return confirmationText;
  }, [archivedState.selectedIds.length, archivedState.listArchivedMode]);

  return (
    <>
      <Grid container spacing={2}>
        <Outlet context={outletContext} />
        <Grid item xs={12} md={12}>
          <Toolbar
            columns={currentColumns}
            setColumns={setColumns}
            dateFrom={dateFrom}
            setDateFrom={setDateFrom}
            dateTo={dateTo}
            setDateTo={setDateTo}
            search={search}
            onSearchChange={handleSearchChange}
            filterProps={{
              columns,
              filterTags,
              onFilterTagChange: handleFilterTagChange,
              filterCategories,
              onFilterCategoryChange: handleFilterCatChange,
              filters,
              setFilters,
            }}
            actions={<Actions archivedState={archivedState} setArchivedState={setArchivedState} />}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Card>
            <TableContainer sx={{ minWidth: '100%', position: 'relative', overflow: 'unset' }}>
              <Scrollbar>
                <Table stickyHeader size="small">
                  {archivedState.bulkArchiveMode || archivedState.listArchivedMode ? (
                    <>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            padding="checkbox"
                            sx={{
                              backgroundColor:
                                archivedState.selectedIds.length > 0 ? '#f0f0f0' : 'transparent',
                            }}
                          >
                            <Checkbox
                              onChange={(e) => handleSelectAll(e.target.checked)}
                              checked={
                                selectableDonorsOnCurrentPage.length > 0 &&
                                selectableDonorsOnCurrentPage.every((donor) =>
                                  archivedState.selectedIds.includes(donor.id)
                                )
                              }
                              disabled={selectableDonorsOnCurrentPage.length === 0}
                              sx={{
                                '&.Mui-disabled': {
                                  cursor: 'not-allowed',
                                  pointerEvents: 'all',
                                  color: (theme) => theme.palette.action.disabledBackground,
                                },
                              }}
                            />
                          </TableCell>
                          <TableCell
                            colSpan={columns.length}
                            width="100"
                            sx={{
                              backgroundColor:
                                archivedState.selectedIds.length > 0 ? '#f0f0f0' : 'transparent',
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}
                            >
                              <Typography sx={{ fontWeight: 'bold', color: '#000' }}>
                                {archivedState.selectedIds.length} Selected
                              </Typography>
                              <Box
                                sx={{
                                  cursor:
                                    archivedState.selectedIds.length === 0
                                      ? 'not-allowed'
                                      : 'pointer',
                                  opacity: archivedState.selectedIds.length === 0 ? 0.5 : 1,
                                  display: 'flex',
                                }}
                              >
                                <Button
                                  color="info"
                                  variant="contained"
                                  startIcon={
                                    <Iconify
                                      sx={{
                                        cursor:
                                          archivedState.selectedIds.length === 0
                                            ? 'not-allowed'
                                            : 'pointer',
                                      }}
                                      width={20}
                                      height={20}
                                      icon="material-symbols:archive"
                                    />
                                  }
                                  sx={archiveDonorsButtonStyle}
                                  component={Link}
                                  onClick={() =>
                                    archivedState.selectedIds.length > 0 &&
                                    setArchivedState((prev) => ({ ...prev, isConfirming: true }))
                                  }
                                >
                                  {archivedState.listArchivedMode ? 'Unarchive' : 'Archive'} Donors
                                </Button>
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </>
                  ) : (
                    <TableHeadCustom
                      order={order}
                      orderBy={orderBy}
                      columns={currentColumns}
                      rowCount={data.length}
                      onSort={onSort as (id: string) => void}
                    />
                  )}
                  <TableBody>
                    {archivedState.isFetching ? (
                      <TableRow>
                        <TableCell colSpan={currentColumns.length} align="center">
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '100px',
                            }}
                          >
                            <CircularProgress />
                          </Box>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <>
                        {currentPageDonors.map((item) => (
                          <Row
                            key={item.id}
                            columns={currentColumns}
                            item={item}
                            onEdit={handleEdit}
                            archivedState={archivedState}
                            setArchivedState={setArchivedState}
                            isDonorSelectable={isDonorSelectable}
                          />
                        ))}
                      </>
                    )}
                    <TableEmptyRows
                      height={52}
                      emptyRows={emptyRows(
                        page,
                        rowsPerPage,
                        archivedState.listArchivedMode ? archivedState.data.length : data.length
                      )}
                    />
                    <TableNoData
                      isNotFound={
                        archivedState.listArchivedMode
                          ? !archivedState.data.length && !archivedState.isFetching
                          : !data.length
                      }
                    />
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>
            <Box sx={{ position: 'relative' }}>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={archivedState.listArchivedMode ? archivedState.data.length : data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
              />
            </Box>
          </Card>
        </Grid>
      </Grid>
      <ConfirmDialog
        title={archivedState.listArchivedMode ? 'Unarchive Donors' : 'Archive Donors'}
        open={archivedState.isConfirming}
        onClose={() => setArchivedState((prev) => ({ ...prev, isConfirming: false }))}
        onConfirm={handleDonors}
        loading={archivedState.isProcessing}
        contentText={archiveDonorConfirmationText}
      />
    </>
  );
}
