import {
  Box,
  Card,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
import { groupBy, round } from 'lodash';
import GmailAlertNotice from '@pages/receipts/shared/GmailAlertNotice';
import { ReceiptReissueBatchSchemaForm } from '@/schemas';
import { TStepProps } from '../../../useSteps';
import useOrg from '@hooks/useOrg';

// ----------------------------------------------------------------------
export default function Overview({
  stepData,
  submittingData,
  updateStepData,
}: TStepProps<ReceiptReissueBatchSchemaForm>) {
  const total = 4;
  const normalize = (value: number) => ((value - 0) * 100) / (total - 0);
  const progress = round(normalize(submittingData.progress));
  const donorCount = Object.keys(groupBy(stepData.receipts, 'donorId')).length;
  const receiptCount = stepData.receipts.length;
  const { org } = useOrg();

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const handleSelectPrintAllDonors = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateStepData({ emailAndPrint: e.target.checked });
  };
  /* eslint-enable @typescript-eslint/no-unused-vars */

  return (
    <Stack alignItems="center" justifyContent="center" flex="1 1 auto">
      <Card
        sx={{
          py: 4,
          px: 8,
          width: '50%',
          border: (theme) => `1px solid ${theme.palette.grey[600]}40`,
          background: (theme) => theme.palette.grey[100],
        }}
      >
        {submittingData.isSubmitting ? (
          <Stack spacing={3} textAlign="center">
            <Typography variant="h6">Issuing receipts... </Typography>

            <Stack direction="column" spacing={1}>
              <Box sx={{ width: '100%' }}>
                <LinearProgress variant="determinate" value={progress} />
              </Box>
            </Stack>
          </Stack>
        ) : (
          <Stack spacing={2} alignItems="center">
            <Stack spacing={2} textAlign="center">
              <Typography variant="h5">Are you ready to reissue Receipts?</Typography>
              <Typography variant="h6" color="text.secondary">
                <strong>{receiptCount}</strong> receipts to <strong>{donorCount}</strong> donors
              </Typography>
            </Stack>
            {stepData.actionType === 'email' && (<Stack spacing={2}>
              <Typography variant="body2" color="text.secondary">
                Additionally, a PDF of all receipts will be downloaded for your own records.
                <br />
                <strong>Note:</strong> This PDF should not be distributed to donors, as it
                would duplicate the attachment they receive via this email send.
              </Typography>
              {receiptCount > 0 && org?.emailSignature?.signatoryEmail.toLowerCase().endsWith('@gmail.com') && <GmailAlertNotice />}
            </Stack>)}
          </Stack>
        )}
      </Card>
    </Stack>
  );
}
