
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, MenuItem, Stack } from '@mui/material';

import useOrg from '@hooks/useOrg';
import PATHS, { getPath } from '@routes/paths';
import { donorsViewCacheId } from '@typedefs/donation';

import Iconify from '@components/Iconify';
import MenuPopover from '@components/MenuPopover';
import useRole from '@hooks/useRole';
import { Role } from '@shared/types/organization';
import * as fn from '@fire/functions';
import { ArchivedStateType } from './List';

// ----------------------------------------------------------------------
type Props = {
  archivedState: ArchivedStateType;
  setArchivedState: React.Dispatch<React.SetStateAction<ArchivedStateType>>;
};

export default function DonorActions({
  archivedState,
  setArchivedState,
}: Props) {

  const { org } = useOrg();
  const { hasAccess } = useRole();

  // ------- STATE -------
  const [openViewsMenu, setViewsMenu] = useState<HTMLElement | null>(null);
  const [openAddMenu, setAddMenu] = useState<HTMLElement | null>(null);

  // ------- ACTIONS -------
  const handleOpenViewsMenu = (e: React.MouseEvent<HTMLElement>) => setViewsMenu(e.currentTarget);
  const handleCloseViewsMenu = () => setViewsMenu(null);
  const handleOpenAddMenu = (e: React.MouseEvent<HTMLElement>) => setAddMenu(e.currentTarget);
  const handleCloseAddMenu = () => setAddMenu(null);

  const handleSelectMenu = () => {
    handleCloseViewsMenu();
    handleCloseAddMenu();
  };

  const handleResetDefaultView = () => {
    localStorage.removeItem(`${donorsViewCacheId}-state`);
    localStorage.removeItem(`${donorsViewCacheId}-tags`);
    localStorage.removeItem(`${donorsViewCacheId}-categories`);
    window.location.reload();
  };

  const fetchArchivedDonors = useCallback(async () => {
    setArchivedState((prev) => ({ ...prev, isFetching: true }));
    try {
      const { data } = await fn.getArchivedDonors({ orgId: org!.id });
      const result = data.donors.map(donor => ({
        name: donor.firstName + ' ' + donor.lastName,
        id: donor.id
      }));

      setArchivedState((prev) => ({ ...prev, data: result, isFetching: false }));
    } catch (e) {
      throw e;
    }
  }, [org, setArchivedState]);

  const loadArchivedDonors = () => {
    fetchArchivedDonors();

    setArchivedState((prev) => ({
      ...prev,
      listArchivedMode: true,
      bulkArchiveMode: false,
      selectedIds: [],
    }));
  }

  // ------- UI -------
  return (
    <Stack spacing={1} direction="row">
      {hasAccess([Role.editor, Role.contributor, Role.donorOnly]) && (
        <>
          {!archivedState.bulkArchiveMode && !archivedState.listArchivedMode && <Button
            color='info'
            variant="contained"
            onClick={() => setArchivedState((prev) => ({
              ...prev,
              bulkArchiveMode: true,
              listArchivedMode: false,
              selectedIds: [],
            }))}
          >
            Archive Donors
          </Button>}
          {archivedState.bulkArchiveMode && <Button
            onClick={() => setArchivedState((prev) => ({
              ...prev,
              bulkArchiveMode: false,
              listArchivedMode: false,
              selectedIds: [],
            }))}
          >
            Switch to List View
          </Button>}
          {archivedState.listArchivedMode && <Button
            color='primary'
            variant="contained"
            onClick={() => setArchivedState((prev) => ({
              ...prev,
              bulkArchiveMode: false,
              listArchivedMode: false,
              selectedIds: [],
            }))}
          >
            Show Active Donors
          </Button>}
          {!archivedState.listArchivedMode &&
            <Button
              variant="contained"
              onClick={handleOpenViewsMenu}
              startIcon={<Iconify icon="material-symbols:view-list" />}
            >
              Views
            </Button>
          }
          <MenuPopover
            open={Boolean(openViewsMenu)}
            anchorEl={openViewsMenu}
            onClose={handleCloseViewsMenu}
            onClick={handleSelectMenu}
            sx={{
              width: 155,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
                '& svg': { mr: 1, width: 20, height: 20 },
              },
            }}
          >
            <MenuItem
              component={Link}
              to={getPath(PATHS.org.donors.export, { orgId: org!.id })}
              onClick={handleSelectMenu}
            >
              <Iconify icon={'uil:file-export'} />
              Export View
            </MenuItem>
            <MenuItem
              component={Link}
              to={getPath(PATHS.org.donors.saveView, { orgId: org!.id })}
              onClick={handleSelectMenu}
            >
              <Iconify icon={'material-symbols:save-outline'} />
              Save View
            </MenuItem>
            <MenuItem
              component={Link}
              to={getPath(PATHS.org.donors.manageViews, { orgId: org!.id })}
              onClick={handleSelectMenu}
            >
              <Iconify icon={'material-symbols:settings-outline'} />
              Manage Views
            </MenuItem>
            <MenuItem onClick={handleResetDefaultView}>
              <Iconify icon={'radix-icons:reset'} />
              Reset View
            </MenuItem>
            <MenuItem onClick={loadArchivedDonors}>
              <Iconify icon={'radix-icons:archive'} />
              Archived Donors
            </MenuItem>
          </MenuPopover>
        </>
      )}
      {hasAccess([Role.editor, Role.contributor, Role.donorOnly]) && (
        <>
        {!archivedState.listArchivedMode &&
          <Button
            variant="contained"
            onClick={handleOpenAddMenu}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New Donor
          </Button>
        }
          <MenuPopover
            open={Boolean(openAddMenu)}
            anchorEl={openAddMenu}
            onClose={handleCloseAddMenu}
            sx={{
              width: 180,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
                '& svg': { mr: 1, width: 20, height: 20 },
              },
            }}
          >
            <MenuItem
              component={Link}
              to={getPath(PATHS.org.donors.donor.create, { orgId: org!.id })}
              onClick={handleSelectMenu}
            >
              <Iconify icon={'ion:person-add-outline'} />
              Single Donor
            </MenuItem>
            <MenuItem
              component={Link}
              to={getPath(PATHS.org.import.root, { orgId: org!.id, source: 'donors' })}
              onClick={handleSelectMenu}
            >
              <Iconify icon="flowbite:file-import-outline" />
              Import
            </MenuItem>
          </MenuPopover>
        </>
      )}
    </Stack>
  );
}
