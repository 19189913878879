import { useEffect, useMemo } from 'react';
import { Typography, Stack, Card, Divider, Slider, Button } from '@mui/material';
import numeral from 'numeral';
import { useSnackbar } from 'notistack';

import { Limits } from '@shared/limits';
import { StripeUtil } from '@shared/stripe';
import useDonation from '@hooks/useDonation';
import useFormat from '@hooks/useFormat';
import useStripe from '@hooks/useStripe';
import useOrg from '@hooks/useOrg';
import useQueryParams from '@hooks/useQueryParams';

import { InfoIcon } from '@/theme/overrides/CustomIcons';

// ----------------------------------------------------------------------
export default function SubscriptionStatus() {
  const { queryParams } = useQueryParams();
  const successCallback = queryParams['success'];
  const { fDate } = useFormat();
  const { donors } = useDonation();
  const { org } = useOrg();
  const { subscription, isFreeTrial, trialRemaining, createPortalSession, customerId } =
    useStripe();
  const { enqueueSnackbar } = useSnackbar();

  const productTierKey = subscription?.product.id
    ? StripeUtil.getProductTierKey(
        import.meta.env.MODE === 'production' ? 'production' : 'development',
        subscription?.product.id
      )
    : undefined;

  const planLimit = Limits.donors(productTierKey);

  // --------------- state ---------------
  const plan = subscription?.product;
  const [renewalText, renewalDate] = useMemo(() => {
    if (subscription) {
      if (subscription.cancelAtPeriodEnd) {
        return ['Expiration Date', fDate(subscription.dateExpiration)];
      } else {
        return ['Renewal Date', fDate(subscription.dateExpiration)];
      }
    }

    return ['Expiration Date', 'Never'];
  }, [fDate, subscription]);

  const manageSubscription = async () => {
    try {
      if (!org || !customerId) {
        console.error('Missing org or customerId');
        return null;
      }

      const sessionUrl = await createPortalSession({
        customerId,
        orgId: org.id,
      });

      window.location.replace(sessionUrl);
    } catch (e) {
      console.error(e);
    }
  };

  // const addonsPrice = (addons: Array<Product>) => {
  //   let price = 0;
  //   addons.forEach((addon) => {
  //     if (!addon.price) return;
  //     price += addon.price;
  //   });
  //   return price / 100;
  // };

  useEffect(() => {
    if (successCallback) {
      enqueueSnackbar('Plan updated!');
    }
  }, [enqueueSnackbar, successCallback]);

  return (
    <>
      <Card sx={{ p: 3, color: (theme) => theme.palette.grey[600], maxWidth: 1100 }}>
        <Stack spacing={2}>
          <Stack direction="row" justifyContent="space-between">
            <Stack>
              <Typography variant="h5">My Subscription</Typography>
              <Typography variant="subtitle1" color="text.primary">
                {plan?.name || 'Free plan'}
              </Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
              {successCallback && (
                <Stack
                  direction="row"
                  alignItems="center"
                  borderRadius={2}
                  p={1}
                  spacing={1}
                  maxHeight={36}
                  sx={{ backgroundColor: (t) => t.palette.warning.light }}
                >
                  <InfoIcon />
                  <Typography variant="body2">
                    Your payment is processing. Once received, your plan will be updated.
                  </Typography>
                </Stack>
              )}
              {plan ? (
                <Stack direction="row" alignItems="baseline" spacing={0.5}>
                  <Typography variant="h4" color="text.primary">
                    {numeral((plan.price || 0) / 100).format('$0,0')}
                  </Typography>
                  <Typography variant="body1">/ year</Typography>
                </Stack>
              ) : (
                <Stack />
              )}
            </Stack>
          </Stack>

          <Stack direction="row" justifyContent="end">
            {customerId ? (
              <Stack direction="row" alignItems="baseline" spacing={0.5}>
                <Button size="medium" variant="contained" onClick={manageSubscription}>
                  Manage
                </Button>
              </Stack>
            ) : (
              <Stack />
            )}
          </Stack>

          <Divider />

          <Stack spacing={3} direction="row">
            <Card
              sx={{
                width: 240,
                p: 1.5,
                background: (theme) => theme.palette.grey[100],
                color: (theme) => theme.palette.grey[600],
                '.MuiSlider-root': {
                  cursor: 'auto',
                },
                '.MuiSlider-thumb': {
                  display: 'none',
                },
              }}
            >
              <Typography variant="subtitle1">Donors</Typography>
              <Typography variant="h4" color="text.primary">
                {donors.length} / {planLimit}
              </Typography>
              <Slider size="medium" value={donors.length} min={0} max={planLimit} />
            </Card>

            {isFreeTrial && (
              <Card
                sx={{
                  width: 240,
                  p: 1.5,
                  background: (theme) => theme.palette.grey[100],
                  color: (theme) => theme.palette.grey[600],
                }}
              >
                <Typography variant="subtitle1">Free trial</Typography>
                <Typography variant="h4" color="text.primary">
                  {trialRemaining}
                </Typography>
              </Card>
            )}

            <Card
              sx={{
                width: 240,
                p: 1.5,
                background: (theme) => theme.palette.grey[100],
                color: (theme) => theme.palette.grey[600],
              }}
            >
              <Typography variant="subtitle1">{renewalText}</Typography>
              <Typography variant="h4" color="text.primary">
                {renewalDate}
              </Typography>
            </Card>
          </Stack>
        </Stack>
      </Card>
    </>
  );
}
