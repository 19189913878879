import { Alert, IconButton, Link, Stack, Typography } from '@mui/material';
import Iconify from '@components/Iconify';

export default function GmailAlertNotice() {
  return (
    <Alert
      severity="info"
      icon={false}
      sx={{
        '.MuiAlert-message': { px: 2 },
        border: (theme) => `1px solid ${theme.palette.grey[600]}14`,
        background: (theme) => theme.palette.grey[200],
      }}
    >
      <Stack
        spacing={1}
        direction="row"
        alignItems="flex-start"
        color="text.secondary"
      >
        <IconButton
          size="small"
          sx={{ color: (theme) => theme.palette.warning.dark }}
        >
          <Iconify icon="ic:baseline-warning-amber" width={24} height={24} />
        </IconButton>
        <Typography variant="body2" sx={{ mt: 1 }}>
          <strong>Important Notice for Gmail Users</strong>
          <br />
          If you are using a personal Gmail account to send receipts, please be
          aware that Google imposes daily sending limits. These limits are managed
          by Google, and we have no control over them.
          <br />
          Please note, we cannot monitor or verify whether emails are successfully
          sent. If any issues arise, you'll need to check directly with your Gmail
          account.
          <br />
          For additional information on Gmail sending limits, please check here:{' '}
          <strong>
            <Link
              href="https://support.google.com/a/answer/166852?hl=en#zippy="
              target="_blank"
            >
              Details
            </Link>
          </strong>
        </Typography>
      </Stack>
    </Alert>
  );
}
