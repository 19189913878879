import { useState } from 'react';
import { TableRow, TableCell, MenuItem, Checkbox, Tooltip } from '@mui/material';

import { TColumn } from '@typedefs/app';
import { TableMoreMenu } from '@components/table';
import Iconify from '@components/Iconify';
import { TagItem } from '@pages/donors/dialogs/Settings/DonorTags/utils/renderTags';
import { ArchivedStateType } from '@pages/donors/List';
import { CategoryItem } from '@pages/donations/dialogs/Settings/DonationCategories/utils/renderCategories';
import type { ArchivedDonorListItem, DonorListHeader, DonorListItem } from './config';

// ----------------------------------------------------------------------
type Props = {
  readonly columns: TColumn<DonorListHeader>[];
  readonly item: DonorListItem | ArchivedDonorListItem;
  readonly onEdit: (id: string) => void;
  readonly archivedState: ArchivedStateType;
  readonly setArchivedState: React.Dispatch<React.SetStateAction<ArchivedStateType>>;
  readonly isDonorSelectable: (item: DonorListItem) => boolean;
};

// ----------------------------------------------------------------------
export default function Row({ item, onEdit, columns, archivedState, setArchivedState, isDonorSelectable }: Props) {
  const isSelected = archivedState.selectedIds.includes(item.id);

  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const handleOpenMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenMenuActions(e.currentTarget);
  };

  const handleCloseMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenMenuActions(null);
  };

  const handleArchive = () => {
    setArchivedState((prevState) => {
      const selectedIds = isSelected
        ? prevState.selectedIds.filter((donorId) => donorId !== item.id)
        : [...prevState.selectedIds, item.id];
      return { ...prevState, selectedIds };
    });
  };

  let cells;

  if('tags' in item) {
    cells = columns
      .filter((c) => (c.visible || ['options'].includes(c.id)) && !c.disabled)
      .map((column) => {
        switch (column.id) {
          case 'tags':
            return (
              <TableCell key={column.id} align={column.align}>
                {item._tags.map((tag) => (
                  <TagItem key={tag.id} tag={tag} />
                ))}
              </TableCell>
            );
          case 'categories':
            return (
              <TableCell key={column.id} align={column.align}>
                {item._categories.map((c) => (
                  <CategoryItem key={c.id} category={c} />
                ))}
              </TableCell>
            );
          case 'options':
            return (
            <TableCell key={column.id} align="right">
            <TableMoreMenu
              open={openMenu}
              onOpen={handleOpenMenu}
              onClose={handleCloseMenu}
              actions={
                <>
                  <MenuItem
                    onClick={(e) => {
                      onEdit(item.id);
                      handleCloseMenu(e);
                    }}
                  >
                    <Iconify icon={'eva:edit-fill'} />
                    Edit
                  </MenuItem>
                </>
                }
              />
            </TableCell>
          );
          case 'archiveDonors':
            return (
              <Tooltip
                title='This donor cannot be archived due to data related to the donor (receipts/donations/pledges) for the current-year. Please remove the donor-related data before archiving.'
                disableHoverListener={isDonorSelectable(item)}
                componentsProps={{
                  tooltip: {
                    sx: {
                      textAlign: 'center'
                    }
                  }
                }}
              >
                <TableCell key={column.id} padding="checkbox">
                  <Checkbox
                    disabled={!isDonorSelectable(item)}
                    checked={isSelected}
                    onChange={(e) => {
                      e.stopPropagation();
                      handleArchive();
                    }}
                    onClick={(e) => e.stopPropagation()}
                    sx={{
                      '&.Mui-disabled': {
                        cursor: 'not-allowed',
                        pointerEvents: 'all',
                        color: (theme) => theme.palette.action.disabledBackground,
                      },
                    }}
                  />
                </TableCell>
              </Tooltip>
            );
          default:
            const value = item[column.id] || '';
            return (
              <TableCell
                key={column.id}
                align={column.align}
                dangerouslySetInnerHTML={{
                  __html: value,
                }}
              />
            );
        }
      })
  } else {
    cells = (
      <>
        <TableCell padding="checkbox">
          <Checkbox
            checked={isSelected}
            onChange={(e) => {
              e.stopPropagation();
              handleArchive();
            }}
            onClick={(e) => e.stopPropagation()}
          />
        </TableCell>
        <TableCell>{item.name}</TableCell>
      </>
    );
  }

  return (
    <TableRow
      key={`donor-row-${item.id}`}
      hover
      onClick={() => archivedState.listArchivedMode ? null : onEdit(item.id)}
      sx={{ cursor: 'pointer', '& strong': { color: (theme) => theme.palette.secondary.light } }}
    >
      {cells}
    </TableRow>
  );
}
