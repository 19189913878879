import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { Grid, Card, Stack, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { dispatch } from '@redux/store';
import { updateUser } from '@redux/slices/auth';
import useAuth from '@hooks/useAuth';
import { UserSchemaForm, userSchemaResolver } from '@/schemas';
import { FormProvider, RHFTextField } from '@components/hook-form';
import EmailState from './EmailState';
import useIsMountedRef from '@hooks/useIsMountedRef';
import { canUpdateEmail } from '@shared/auth';

// ----------------------------------------------------------------------
export default function AccountGeneral() {
  const { user } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues: UserSchemaForm = {
    displayName: user?.displayName || '',
    email: user?.email || '',
  };

  const methods = useForm<UserSchemaForm>({
    resolver: userSchemaResolver,
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data: UserSchemaForm) => {
    try {
      const updateData = {
        displayName: data.displayName,
        ...(data.email.toLowerCase() !== user?.email?.toLowerCase() && {
          email: data.email.toLowerCase(),
        }),
      };
      const res = await dispatch(updateUser(updateData));

      res ? enqueueSnackbar('Updated!') : enqueueSnackbar('Failed to update', { variant: 'error' });
    } catch (e) {
      console.error(e);
      reset();
      if (isMountedRef.current) {
        setError('afterSubmit', { ...e, message: e.message });
      }
    }
  };

  // const handleDrop = useCallback((acceptedFiles: File[]) => {
  //   const file = acceptedFiles[0];

  //   if (file) {
  //     // setValue(
  //     //   'photoURL',
  //     //   Object.assign(file, {
  //     //     preview: URL.createObjectURL(file),
  //     //   })
  //     // );
  //     console.log(
  //       Object.assign(file, {
  //         preview: URL.createObjectURL(file),
  //       })
  //     );
  //   }
  // }, []);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={9} lg={6} xl={5}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

          <Card sx={{ p: 3 }}>
            <Stack spacing={2}>
              <RHFTextField
                name="displayName"
                label="Your full name"
                inputProps={{ maxLength: 40 }}
              />
              <RHFTextField
                name="email"
                label="Email address"
                disabled={!canUpdateEmail(user?.providers)}
                inputProps={{ maxLength: 60 }}
                helperText={
                  !canUpdateEmail(user?.providers) &&
                  'Email changes require password authentication.'
                }
              />
            </Stack>

            <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
                disabled={
                  methods.watch('email') === user?.email &&
                  methods.watch('displayName') === user?.displayName
                }
              >
                Save Changes
              </LoadingButton>
            </Stack>
          </Card>
        </FormProvider>
      </Grid>
      {user?.emailVerified ? (
        <></>
      ) : (
        <Grid item xs={12} md={9} lg={6} xl={5}>
          <EmailState />
        </Grid>
      )}
    </Grid>
  );
}
