import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { useSnackbar } from 'notistack';
import { saveAs } from 'file-saver';
import * as Sentry from '@sentry/react';
import { partition } from 'lodash';
import { set } from 'date-fns';

import * as analytics from '@fire/analytics';
import useOrg from '@hooks/useOrg';
import useFormat from '@hooks/useFormat';
import useDonation from '@hooks/useDonation';
import { ReceiptReissueSchema, ReceiptReissueSchemaForm } from '@/schemas';
import { Donor, ReceiptAction } from '@shared/types';
import { PageSize } from '@typedefs/app';
import { TReceiptDonorGroup, ReceiptDocumentProps } from '@typedefs/donation';

import useSteps, { StepId } from '../useSteps';
import StepReissueType from './steps/ReissueType';
import StepSendType from '../Create/steps/SendType';
import StepDonations from './steps/Donations';
import StepReviewReceipt from './steps/ReviewReceipt';
import StepReviewEmail from './steps/ReviewEmail';
import {
  renderTemplate,
  renderSubject,
  defaultState,
  defaultSubject,
} from '../Create/steps/ReviewEmail/EmailTemplate';
import StepOverview from './steps/Overview';

import { getReceiptPdfWorker } from '@workers/receiptPdfWorker';
// ----------------------------------------------------------------------

const pdfWorker = getReceiptPdfWorker();

export default function ReceiptsReissue() {
  const { org } = useOrg();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { fReceiptNumber, fDateToYearStart, fDateToYearEnd, fDateToISO, fFullName } = useFormat();
  const {
    getReceiptById,
    getDonorById,
    sendReceiptEmail,
    reissueReceipt,
    getReceiptReissueGroup,
    getReceiptDonorGroup,
  } = useDonation();

  const receipt = useMemo(() => getReceiptById(params.receiptId), [getReceiptById, params]);
  const donor = useMemo(() => getDonorById(receipt?.donorId), [getDonorById, receipt?.donorId]);

  // --------------- effects ---------------
  const onComplete = (stepData: ReceiptReissueSchemaForm) => onSubmit(stepData);
  const renderSteps = (stepData: ReceiptReissueSchemaForm) => [
    {
      id: StepId.reissueType,
      title: 'Reissue Type',
      Component: StepReissueType,
    },
    {
      id: StepId.sendType,
      title: 'Send Type',
      Component: StepSendType,
    },
    {
      id: StepId.donationsReview,
      title: 'Donations Review',
      Component: StepDonations,
    },
    {
      id: StepId.receiptReview,
      title: 'Receipt Review',
      Component: StepReviewReceipt,
    },
    ...(stepData.actionType === 'email'
      ? [
          {
            id: StepId.emailReview,
            title: 'Email Review',
            Component: StepReviewEmail,
          },
        ]
      : []),
    {
      id: StepId.overview,
      title: 'Final Submission',
      Component: StepOverview,
    },
  ];

  const [dateFrom, dateTo] = useMemo(() => {
    if (!receipt) {
      return [undefined, undefined];
    }
    const yearDate = set(new Date(), { year: receipt.year });
    return [fDateToYearStart(yearDate), fDateToYearEnd(yearDate)];
  }, [fDateToYearStart, fDateToYearEnd, receipt]);

  const { ui, setErrors, setFailed, setSubmittingData, handleClose } = useSteps(
    {
      Schema: ReceiptReissueSchema,
      onComplete,
      title: receipt ? `Reissue receipt ${fReceiptNumber(receipt.number, receipt.year)}` : '',
      actionText: 'Reissue',
      renderSteps,
    },
    {
      receipt,
      reissue: true,
      includeDonations: false,
      dateFrom,
      dateTo,
      email: {
        subject: org?.receiptEmailSubject || defaultSubject,
        html: '',
        state: org?.receiptEmailBody || defaultState,
      },
    }
  );

  useEffect(() => {
    if (!receipt) {
      handleClose();
    }
  }, [handleClose, receipt]);

  // --------------- actions ---------------
  const generatePdfAsync = async (props: ReceiptDocumentProps): Promise<{ blob: Blob; url: string }> => {
    try {
      return await pdfWorker.generateReceiptPDF(props);
    } catch (error) {
      console.error('Error generating PDF:', error);
      throw error;
    }
  };

  const onSubmit = async (stepData: ReceiptReissueSchemaForm) => {
    setSubmittingData({ isSubmitting: true, progress: 1 });

    try {
      // this throws an error in validation
      await ReceiptReissueSchema.validate(stepData, { abortEarly: false });

      const { actionType, letter, email } = stepData;
      if (!org || !actionType || !letter.size || !letter.Doc || !donor) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal, no-throw-literal
        throw { errors: ['Missing required data!'] };
      }

      if (actionType === 'email') {
        if (!email.subject || !email.html) {
          // eslint-disable-next-line @typescript-eslint/no-throw-literal, no-throw-literal
          throw { errors: ['Missing email data!'] };
        }
      }

      // const t = Sentry.startTransaction({
      //   name: 'Reissue receipts',
      //   op: 'processReceipts',
      //   data: { type: actionType },
      // }) as Sentry.Transaction;

      // reissue
      const { receiptGroup } = await reissue(actionType, stepData);

      // give outcome
      if (actionType === 'email') {
        await processReceiptsEmail(receiptGroup, donor, stepData);
      } else {
        await processReceiptsPrint(receiptGroup, stepData);
      }

      // t.finish();

      enqueueSnackbar('Receipt reissued!');
      analytics.donation.receiptReissue(1, actionType);
      handleClose();
    } catch (e) {
      enqueueSnackbar(`Error re-issuing receipt! ${e.message}`, { variant: 'error' });
      Sentry.captureException(e, {
        extra: {
          receiptFlow: 'reissue',
          ...stepData,
          donations: stepData.donations.length,
        },
      });
      setErrors(e.errors);
      setFailed();
    }

    setSubmittingData({ isSubmitting: false, progress: 0 });
  };

  // --------------- issuing ---------------
  const reissue = async (
    issueType: ReceiptAction.ReceiptActionType,
    { receipt, donations: allDonations, reissue }: ReceiptReissueSchemaForm
  ) => {
    setSubmittingData({ isSubmitting: true, progress: 2 });

    const {
      groups: [{ type, number }],
    } = getReceiptReissueGroup(org!, receipt, allDonations, reissue);
    const [currentDonations, additiveDonations] = partition(allDonations, (d) =>
      d.receiptIds.includes(receipt.id)
    );

    const { oldReceipt, newReceipt, donations } = await reissueReceipt({
      orgId: org!.id,
      year: receipt.year,
      date: fDateToISO(),
      // its CA and its reissue (but use from render group to match)
      issueNew: receipt.number !== number,

      donorId: receipt.donorId,
      receiptId: receipt.id,
      currentDonationIds: currentDonations.map((d) => d.id),
      additiveDonationIds: additiveDonations.map((d) => d.id),

      issueType,
      reissueType: type,
    });

    const receiptGroup = getReceiptDonorGroup(org!, newReceipt || oldReceipt, donations);

    return { receiptGroup };
  };

  // --------------- email ---------------
  const processReceiptsEmail = async (
    receiptGroup: TReceiptDonorGroup,
    donor: Donor.Donor,
    data: ReceiptReissueSchemaForm
  ) => {
    const { email, letter } = data;
    if (!org || !letter.Doc) return;

    // -------------------- generation --------------------
    setSubmittingData({ isSubmitting: true, progress: 3 });

    const receiptDocument: ReceiptDocumentProps = {
      title: 'Receipt',
      description: 'Receipt',
      size: PageSize[letter.size!],
      donorGroups: JSON.stringify([receiptGroup]),
      withLetter: data.letter.withLetter,
      options: data.options,
      year: receiptGroup.groups[0].year,
      orientation: 'portrait',
    }

    const { url } = await generatePdfAsync(receiptDocument);

    // -------------------- send email --------------------
    setSubmittingData({ isSubmitting: true, progress: 4 });

    // temporarily enabling print in all cases
    // if on top of emailing, user wants to print
    // if (data.emailAndPrint) {
      await processReceiptsPrint(receiptGroup, data);
    // }

    await sendReceiptEmail({
      orgId: org.id,
      emailSignature: org.emailSignature!,
      emails: [
        {
          to: `${fFullName(donor)} <${donor.email!}>`,
          subject: renderSubject(receiptGroup.vars, email.subject),
          body: renderTemplate(receiptGroup.vars, email.html),
          attachment: url,
        },
      ],
    });
  };

  // --------------- print ---------------
  const processReceiptsPrint = async (
    receiptGroup: TReceiptDonorGroup,
    data: ReceiptReissueSchemaForm
  ) => {
    const { letter } = data;
    if (!org || !letter.Doc) return;

    // -------------------- generation --------------------
    setSubmittingData({ isSubmitting: true, progress: 3 });

    const receiptDocument: ReceiptDocumentProps = {
      title: 'Receipt',
      description: 'Receipt',
      size: PageSize[letter.size!],
      donorGroups: JSON.stringify([receiptGroup]),
      withLetter: data.letter.withLetter,
      options: data.options,
      year: receiptGroup.groups[0].year,
      orientation: 'portrait',
    }

    const { blob } = await generatePdfAsync(receiptDocument);

    // -------------------- zipping --------------------
    setSubmittingData({ isSubmitting: true, progress: 4 });

    // should be a single group since its a single receipt
    const group = receiptGroup.groups[0];
    const receiptNumber = fReceiptNumber(group.number, group.year);

    saveAs(blob, `${receiptNumber}-receipt.pdf`);
  };

  // --------------- UI ---------------
  return ui;
}
