import {
  Stack,
  Box,
  Card,
  LinearProgress,
  Typography,
} from '@mui/material';
import { round } from 'lodash';
import GmailAlertNotice from '@pages/receipts/shared/GmailAlertNotice';

import { ReceiptIssueSchemaForm } from '@/schemas';
import { TStepProps } from '../../../useSteps';
import useOrg from '@hooks/useOrg';

// ----------------------------------------------------------------------
export default function Overview({
  stepData,
  submittingData,
  updateStepData,
}: TStepProps<ReceiptIssueSchemaForm>) {
  const total = 4;
  const normalize = (value: number) => ((value - 0) * 100) / (total - 0);
  const progress = round(normalize(submittingData.progress));
  const { org } = useOrg();

  const donors = Object.values(stepData.donors);
  const totalDonations = donors.reduce((acc, donor) => (acc += donor.donations.length), 0);

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const handleSelectPrintAllDonors = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateStepData({ emailAndPrint: e.target.checked });
  };
  /* eslint-enable @typescript-eslint/no-unused-vars */

  return (
    <Stack alignItems="center" justifyContent="center" flex="1 1 auto">
      <Card
        sx={{
          py: 4,
          px: 8,
          width: '50%',
          border: (theme) => `1px solid ${theme.palette.grey[600]}40`,
          background: (theme) => theme.palette.grey[100],
        }}
      >
        {submittingData.isSubmitting ? (
          <Stack spacing={3} textAlign="center">
            <Typography variant="h6">Issuing receipts... </Typography>

            <Stack direction="column" spacing={1}>
              <Box sx={{ width: '100%' }}>
                <LinearProgress variant="determinate" value={progress} />
              </Box>
            </Stack>
          </Stack>
        ) : (
          <Stack spacing={2} alignItems="center">
            <Stack spacing={2} textAlign="center">
              <Typography variant="h5">Are you ready to issue Receipts?</Typography>
              <Typography variant="h6" color="text.secondary">
                <strong>{donors.length}</strong> donor(s), totaling{' '}
                <strong>{totalDonations}</strong> donation(s)
              </Typography>
            </Stack>

            {stepData.actionType === 'email' ? (
              <Stack spacing={2}>
                <Typography variant="body2" color="text.secondary">
                  Clicking <strong>"Issue receipts"</strong> will email out all{' '}
                  {stepData.letter.withLetter && 'letters and'}
                  receipts to the donor(s). It will also mark the receipts as “issued” for each
                  donor and donation included.
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Additionally, a PDF of all receipts will be downloaded for your own records.
                  <br />
                  <strong>Note:</strong> This PDF should not be distributed to donors, as it
                  would duplicate the attachment they receive via this email send.
                </Typography>

                {org?.emailSignature?.signatoryEmail.toLowerCase().endsWith('@gmail.com') && <GmailAlertNotice />}

                {/* <Divider />

                <Stack spacing={1}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={stepData.emailAndPrint}
                          onChange={handleSelectPrintAllDonors}
                        />
                      }
                      label={
                        <Typography variant="body2" color="text.secondary">
                          Additionally, <strong>download a PDF of all receipts</strong> upon "Issue
                          Receipts", for your own records.
                          <br />
                          <strong>Note:</strong> This PDF should not be distributed to donors, as it
                          would duplicate the attachment they receive via this email send.
                        </Typography>
                      }
                      sx={{ alignItems: 'flex-start', pl: 1.3 }}
                    />
                  </FormGroup>
                  {donors.length > 0 &&
                    org?.emailSignature?.signatoryEmail.toLowerCase().endsWith('@gmail.com') && (
                      <Alert
                        severity="info"
                        icon={false}
                        sx={{
                          '.MuiAlert-message': { px: 2 },
                          border: (theme) => `1px solid ${theme.palette.grey[600]}14`,
                          background: (theme) => theme.palette.grey[200],
                        }}
                      >
                        <Stack
                          spacing={1}
                          direction="row"
                          alignItems="flex-start"
                          color="text.secondary"
                        >
                          <IconButton
                            size="small"
                            sx={{ color: (theme) => theme.palette.warning.dark }}
                          >
                            <Iconify icon="ic:baseline-warning-amber" width={24} height={24} />
                          </IconButton>
                          <Typography variant="body2" sx={{ mt: 1 }}>
                            <strong>Important Notice for Gmail Users</strong>
                            <br />
                            If you are using a personal Gmail account to send receipts, please be
                            aware that Google imposes daily sending limits. These limits are managed
                            by Google, and we have no control over them.
                            <br />
                            Please note, we cannot monitor or verify whether emails are successfully
                            sent. If any issues arise, you'll need to check directly with your Gmail
                            account.
                            <br />
                            For additional information on Gmail sending limits, please check here:{' '}
                            <strong>
                              <Link
                                href="https://support.google.com/a/answer/166852?hl=en#zippy="
                                target="_blank"
                              >
                                Details
                              </Link>
                            </strong>
                          </Typography>
                        </Stack>
                      </Alert>
                    )}
                </Stack> */}
              </Stack>
            ) : (
              <Stack>
                <Typography variant="body2" color="text.secondary">
                  Clicking <strong>"Issue receipts"</strong> will download a PDF file of all{' '}
                  {stepData.letter.withLetter && 'letters and'} receipts for you to print and mail
                  out. It will also mark the receipts as “issued” for each donor and donation
                  included.
                </Typography>
              </Stack>
            )}
          </Stack>
        )}
      </Card>
    </Stack>
  );
}
